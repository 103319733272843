// ContentView.js
import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Divider } from "react-native-elements";

import Form from "../Form";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Button from "../../presentational/Button";
import Modal from "../../functional/Modal";
import styled from "styled-components/native";
import WorkScopeToolList from "../../functional/List/WorkScopeToolList";
import Text from "../../presentational/Text";
import Gap from "../../presentational/Gap";

const Label = styled(Text)`
	color: #00205b;
`;

const ContentView = ({ selectedItem }) => {
	// Store Hooks
	const {
		workscope: { input, toolInput, list, toolList, expandableInput },
		workpackage: { list: wpList },
		file: { engineFamilyList, engineVariantList },
	} = useStoreState((state) => state);

	const {
		getWorkScopeToolsThunk,
		setWorkScopeInputAction,
		setWorkScopeToolInputAction,
		upsertWorkScopeToolThunk,
		upsertWorkScopeThunk,
		deleteWorkScopeThunk,
		setWorkScopeExpandableInputAction,
	} = useStoreActions((actions) => actions);

	const [toolModalVisible, setToolSelectionModalVisible] = useState(false);

	useEffect(() => {
		if (!selectedItem) return;
		if (input.id !== selectedItem.id) {
			const selectedData = list.find((ws) => ws.id === selectedItem.id);
			if (selectedData) {
				setWorkScopeInputAction(selectedData);
				getWorkScopeToolsThunk({ id: selectedItem.id });
			}
		}
	}, [
		selectedItem,
		list,
		input.id,
		setWorkScopeInputAction,
		getWorkScopeToolsThunk,
	]);

	return (
		<View style={styles.contentContainer}>
			<Modal
				title="Add Work Scope Tool"
				isVisible={toolModalVisible}
				onClose={() => setToolSelectionModalVisible(false)}
			>
				<Row>
					<Col>
						<Input
							label="Part Number"
							onChangeText={(partnumber) =>
								setWorkScopeToolInputAction({ partnumber })
							}
							value={toolInput.partnumber}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Input
							label="Tool Nomenclature"
							onChangeText={(toolnomenclature) =>
								setWorkScopeToolInputAction({ toolnomenclature })
							}
							value={toolInput.toolnomenclature}
							required
						/>
					</Col>
				</Row>

				<View style={styles.modalFooter}>
					<TouchableOpacity style={[styles.button, styles.cancelButton]}>
						<Text style={styles.buttonText}>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.button, styles.saveButton]}
						onPress={() => {
							upsertWorkScopeToolThunk();
						}}
					>
						<Text style={[styles.buttonText, styles.saveButtonText]}>
							Save Selection
						</Text>
					</TouchableOpacity>
				</View>
			</Modal>
			{expandableInput.enginefamily && expandableInput.enginevariant && (
				<>
					<Row>
						<Text>
							{expandableInput.enginefamily} / {expandableInput.enginevariant}
						</Text>
					</Row>
					<Gap />
					<Divider />
				</>
			)}
			<Gap />

			{selectedItem ? (
				<Form>
					<Row>
						<Col xs={12} md={8}>
							<Input
								label="Work Scope Name"
								onChangeText={(workscopename) =>
									setWorkScopeInputAction({ workscopename })
								}
								value={input.workscopename}
								required
							/>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									deleteWorkScopeThunk();
								}}
								color="#ab2328"
								fullWidth
							>
								Delete
							</Button>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									upsertWorkScopeThunk();
								}}
								color="#1976d2"
								fullWidth
							>
								Save
							</Button>
						</Col>
					</Row>

					<Row>
						<Col>
							<Input
								label="Work Scope Description"
								onChangeText={(workscopedescription) =>
									setWorkScopeInputAction({ workscopedescription })
								}
								value={input.workscopedescription}
								required
							/>
						</Col>
					</Row>

					{/* <Row>
						<Col xs={12} md={5}>
							<Input
								label="Work Scope Parent"
								onChangeText={(wsparentid) =>
									setWorkScopeInputAction({ wsparentid })
								}
								value={input.wsparentid}
								required
							/>
						</Col>
						<Col xs={12} md={7}>
							<Input
								label="Work Scope Order"
								onChangeText={(ordernum) =>
									setWorkScopeInputAction({ ordernum })
								}
								value={input.ordernum}
								required
							/>
						</Col>
					</Row> */}
					<Row>
						<Col xs={12} md={4}>
							<Input
								label="Work Scope Parent Key"
								onChangeText={(wsparentid) =>
									setWorkScopeInputAction({ wsparentid })
								}
								value={input.wsparentid}
								required
								editable={false}
							/>
						</Col>
						<Col xs={12} md={5}>
							<Input
								label="Work Scope Parent Name"
								onSelect={(item) =>
									setWorkScopeInputAction({ wsparentid: item.id })
								}
								// value={input.wsparentid}
								value={
									list.find((ws) => ws.id === input.wsparentid)?.workscopename
								}
								required
								editable={true}
								dropdownChoices={
									// map through the list and only show the name of the level 1 items
									list.map((ws) => ({
										id: ws.id,
										name: ws.workscopename,
									}))
								}
								dropdown
								selectedItem={(item) => item.name}
								rowTextForSelection={(item) => item.name}
							/>
						</Col>
						<Col xs={12} md={3}>
							<Input
								label="Work Scope Order"
								onChangeText={(ordernum) =>
									setWorkScopeInputAction({ ordernum })
								}
								value={input.ordernum}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={3}>
							<Input
								label="Work Package"
								value={
									input.workpackageid
										? wpList?.find((w) => w.id === input.workpackageid)
												?.description
										: "N/A"
								}
								onSelect={(item) => {
									setWorkScopeInputAction({
										workpackageid: item.id,
										wpdescription: item.description,
									});
								}}
								required
								dropdown
								dropdownChoices={wpList}
								selectedItem={(item) => item.description}
								rowTextForSelection={(item) => item.description}
							/>
						</Col>
						<Col xs={12} md={9}>
							<Input
								editable={false}
								label="Work Package Description"
								onChangeText={(wpdescription) =>
									setWorkScopeInputAction({ wpdescription })
								}
								value={input.wpdescription || "N/A"}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<Label>Notes</Label>
							<Input
								multiline
								numberOfLines={4}
								onChangeText={(notes) => setWorkScopeInputAction({ notes })}
								value={input.notes}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<View style={styles.toolsHeader}>
								<Label>Tools</Label>
								<TouchableOpacity
									style={styles.addButton}
									onPress={() => setToolSelectionModalVisible(true)}
								>
									<Text style={styles.addButtonText}>Add</Text>
								</TouchableOpacity>
							</View>
							<WorkScopeToolList />
						</Col>
					</Row>
				</Form>
			) : (
				<Text style={styles.noContentText}>Select an item to view details</Text>
			)}
		</View>
	);
};

export default ContentView;

const styles = StyleSheet.create({
	contentContainer: {
		padding: 20,
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		paddingTop: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
	toolsHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	addButton: {
		backgroundColor: "#1976d2",
		padding: 8,
		borderRadius: 4,
		margin: 5,
	},
	addButtonText: {
		color: "white",
	},
	noContentText: {
		fontSize: 16,
		color: "#666",
		textAlign: "center",
	},
});
