// treeUtils.js
/**
 * findItemById: recursively finds a node in the data tree by id
 */
export const findItemById = (items, id) => {
	for (const itm of items) {
		if (itm.id === id) return itm;
		if (itm.children?.length) {
			const found = findItemById(itm.children, id);
			if (found) return found;
		}
	}
	return null;
};

/**
 * removeItemById: removes a node from the tree by id
 */
export const removeItemById = (items, id) => {
	return items
		.map((itm) => {
			if (itm.id === id) return null;
			if (itm.children?.length) {
				return {
					...itm,
					children: removeItemById(itm.children, id).filter(Boolean),
				};
			}
			return itm;
		})
		.filter(Boolean);
};

/**
 * addToTarget: adds a "draggedItem" as a child of "targetId"
 */
export const addToTarget = (items, targetId, draggedItem) => {
	return items.map((node) => {
		if (node.id === targetId) {
			return {
				...node,
				children: [...(node.children || []), draggedItem],
			};
		}
		if (node.children?.length) {
			return {
				...node,
				children: addToTarget(node.children, targetId, draggedItem),
			};
		}
		return node;
	});
};
