import { createFilterHeaders } from "../../utilities/filter";

// const defaultWorkScopeInput = {
// 	id: null,
// 	enginefamilyid: "",
// 	enginefamily: "",
// 	enginevariantid: "",
// 	enginevariant: "",
// 	workscopedescription: "",
// 	workpackagedescription: "",
// 	partnumber: "",
// 	toolnomenclature: "",
// 	technicalmanualreference: "",
// 	quantity: "",
// 	engine: "",
// 	enginemodel: "",
// 	wiptool: "",
// 	usewith: "",
// 	toolcode: "",
// 	qualifiedrevision: "",
// 	ceukca: "",
// 	toolsfunction: "",
// 	line: "",
// 	module: "",
// 	shop: "",
// 	repair: "",
// 	lmtother: "",
// 	duplicate: "",
// 	tpmcomments: "",
// 	dimensions: "",
// 	weight: "",
// 	loadtest: "",
// 	calibration: "",
// 	wsfilename: "",
// };

const defaultWorkScopeInput = {
	id: "",
	workscopename: "",
	enginefamilyid: "",
	enginevariantid: "",
	workpackageid: "",
	technicalmanualreference: "",
	quantity: "",
	wiptool: "",
	usewith: "",
	ceukca: "",
	toolsfunction: "",
	line: "",
	module: "",
	shop: "",
	repair: "",
	lmtother: "",
	duplicate: "",
	tpmcomments: "",
	alternateprocedureflag: "",
	alternateprocedurenotes: "",
	wpdescription: "",
	wsparentid: "",
	notes: "",
	user: "",
};

const defaultWorkScopeToolInput = {
	id: null,
	// workscopeid: null,
	partnumber: "",
	toolnomenclature: "",
	toolcode: "",
	qualifiedrevision: "",
	dimensions: "",
	weight: "",
	loadtest: "",
	calibration: "",
	user: "",
};

const state = {
	workscope: {
		input: defaultWorkScopeInput,
		removeFileMappingAsAdminAreYouSureModalVisible: false,
		list: [],
		showUploadWorkScopeFileModal: false,
		workScopeMasterToolListSearchInput: "",
		workScopeMasterToolHeaders: createFilterHeaders([
			{
				columnName: "Technical Workscope",
				propertyName: "workscopedescription",
			},
			{
				columnName: "Part Number",
				propertyName: "partnumber",
			},
			{
				columnName: "Work Package Description",
				propertyName: "workpackagedescription",
				freeze: true,
			},
			{
				columnName: "Engine",
				propertyName: "engine",
			},
			{
				columnName: "Engine Model",
				propertyName: "enginemodel",
			},
			{
				columnName: "Technical Manual Reference",
				propertyName: "technicalmanualreference",
			},
			{
				columnName: "Tool Nomenclature",
				propertyName: "toolnomenclature",
			},
			{
				columnName: "Quantity",
				propertyName: "quantity",
			},
			{
				columnName: "Tool Code",
				propertyName: "toolcode",
			},
			{
				columnName: "Qual. / Validation Status",
				propertyName: "qualifiedrevision",
			},
			{
				columnName: "Replaced By Part Number",
				propertyName: "replacedbypartnumber",
			},
			{
				columnName: "Replaced By Status Code",
				propertyName: "replacedbystatuscode",
			},
			{
				columnName: "Replaced By Qualification Code",
				propertyName: "replacedByqualificationcode",
			},
			{
				columnName: "Date Modified",
				propertyName: "datemodified",
			},
		]),
		currWorkScopeMasterToolListPageNumber: 1,
		selectionOptions: [],
		engineInput: "",
		engineModelInput: "",
		workScopeNameInput: "",
		workScopeFilePreview: [],
		filePreviewModalVisible: false,
		selectedData: [],
		toolList: [],
		toolHeaders: createFilterHeaders([
			{
				columnName: "Part Number",
				propertyName: "partnumber",
			},
			{
				columnName: "Tool Nomenclature",
				propertyName: "toolnomenclature",
			},
			{
				columnName: "Rhinestahl Recommendation",
				subheaders: [
					{
						columnName: "Part Number",
						propertyName: "override_replaceby_partNumber",
					},
					{
						columnName: "Status Code",
						propertyName: "override_replaceby_TSCode",
					},
					{
						columnName: "Qualification",
						propertyName: "override_replaceby_qualificationCode",
					},
				],
			},
			{
				columnName: "Delete",
				propertyName: "delete",
			},
		]),
		currListPageNumber: 1,
		toolInput: defaultWorkScopeToolInput,
		expandableInput: {
			enginefamilyid: "",
			enginevariantid: "",
			enginevariant: "",
			enginefamily: "",
		},
	},
};

export default state;
