import { Center } from "@cfbs/cfbsstrap-native";
import {
	useUploadWorkScopeFileModal,
	useWorkScopeFilePreviewModal,
} from "../../../hooks/workscope";
import Button from "../../presentational/Button";
import Modal from "./Modal";
import Gap from "../../presentational/Gap";
import Col from "../../presentational/Col";
import SelectBox from "react-native-multi-selectbox";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { xorBy } from "lodash";
import Input from "../../presentational/Input";
import { height, width } from "../../../utilities/general";
import { ScrollView, StyleSheet, View } from "react-native";
import TreeItem from "../../presentational/Tree/TreeItem";
import { TouchableOpacity } from "react-native";
import Text from "../../presentational/Text";
import { useNavigation } from "@react-navigation/native";

function FilePreviewModal(props) {
	const navigation = useNavigation();
	const { workScopeFilePreview } = useStoreState((state) => state.workscope);
	const { processWorkScopeFileThunk } = useStoreActions((actions) => actions);
	const { ...modalProps } = useWorkScopeFilePreviewModal();
	const [selectedId, setSelectedId] = useState(null);
	const [expandedIds, setExpandedIds] = useState(new Set());

	const toggleExpanded = (id) => {
		setExpandedIds((prev) => {
			const next = new Set(prev);
			if (next.has(id)) {
				next.delete(id);
			} else {
				next.add(id);
			}
			return next;
		});
	};

	const findSelectedItem = (items, id) => {
		for (const item of items) {
			if (item.id === id) return item;
			if (item.children) {
				const found = findSelectedItem(item.children, id);
				if (found) return found;
			}
		}
		return null;
	};
	const [data, setData] = useState([]);

	// function mapDataToTreeStructure(items) {
	// 	if (!items) {
	// 		return [];
	// 	}
	// 	// First group by parent workscope
	// 	const parentGrouped = items.reduce((acc, item) => {
	// 		if (!acc[item.parentworkscopename]) {
	// 			acc[item.parentworkscopename] = {
	// 				id: `parent_${item.parentworkscopename}`,
	// 				name: item.parentworkscopename,
	// 				level: item.plevel,
	// 				children: {},
	// 			};
	// 		}

	// 		// Then group workscopes under each parent
	// 		const workscopes = acc[item.parentworkscopename].children;
	// 		if (!workscopes[item.workscopename]) {
	// 			workscopes[item.workscopename] = {
	// 				id: `workscope_${item.wslevel}`,
	// 				name: item.workscopename,
	// 				level: item.wslevel,
	// 				children: [],
	// 			};
	// 		}

	// 		// Add work package details under workscope
	// 		workscopes[item.workscopename].children.push({
	// 			id: item.workpackageid,
	// 			name: item.workpackagedescription,
	// 			toolData: {
	// 				partnumber: item.partnumber,
	// 				toolnomenclature: item.toolnomenclature,
	// 				quantity: item.quantity,
	// 				toolcode: item.toolcode,
	// 				qualifiedrevision: item.qualifiedrevision,
	// 				dimensions: item.dimensions,
	// 				weight: item.weight,
	// 			},
	// 		});

	// 		return acc;
	// 	}, {});

	// 	// Convert the nested objects to arrays
	// 	return Object.values(parentGrouped).map((parent) => ({
	// 		...parent,
	// 		children: Object.values(parent.children).map((workscope) => ({
	// 			...workscope,
	// 			children: workscope.children,
	// 		})),
	// 	}));
	// }

	function mapDataToTreeStructure(items) {
		if (!items) {
			return [];
		}

		// First group by parent workscope
		const parentGrouped = items.reduce((acc, item) => {
			const parentId = `parent_${item.plevel}_${item.enginefamilyid}_${item.enginevariantid}_${item.parentworkscopename}`;

			if (!acc[parentId]) {
				acc[parentId] = {
					id: parentId,
					name: item.parentworkscopename,
					level: item.plevel,
					children: {},
				};
			}

			// Then group workscopes under each parent
			const workscopes = acc[parentId].children;
			const workscopeId = `workscope_${item.wslevel}_${item.enginefamilyid}_${item.enginevariantid}_${item.workscopename}`;

			if (!workscopes[workscopeId]) {
				workscopes[workscopeId] = {
					id: workscopeId,
					name: item.workscopename,
					level: item.wslevel,
					engineFamilyId: item.enginefamilyid,
					engineVariantId: item.enginevariantid,
					children: [],
				};
			}

			// Add work package details under workscope with unique ID
			const workPackageId = `package_${item.workpackageid}_${item.enginefamilyid}_${item.enginevariantid}_${item.enginevariantdesc}`;

			workscopes[workscopeId].children.push({
				id: workPackageId,
				name: `${item.partnumber} - ${item.toolnomenclature}`,
				engineVariantDesc: item.enginevariantdesc,
				toolData: {
					partnumber: item.partnumber,
					toolnomenclature: item.toolnomenclature,
					quantity: item.quantity,
					toolcode: item.toolcode,
					qualifiedrevision: item.qualifiedrevision,
					dimensions: item.dimensions,
					weight: item.weight,
				},
			});

			return acc;
		}, {});

		// Convert the nested objects to arrays
		return Object.values(parentGrouped).map((parent) => ({
			...parent,
			children: Object.values(parent.children).map((workscope) => ({
				...workscope,
				children: workscope.children,
			})),
		}));
	}

	useEffect(() => {
		if (workScopeFilePreview) {
			setData(mapDataToTreeStructure(workScopeFilePreview));
		}
	}, [workScopeFilePreview]);

	return (
		<Modal
			{...modalProps}
			{...props}
			style={{
				width: width / 1.7,
				height: height / 1.7,
			}}
		>
			<Center>
				<ScrollView
					style={{
						width: "100%",
						height: "100%",
					}}
					contentContainerStyle={{
						width: width / 2,
						height: height / 2,
					}}
				>
					<Gap />
					{data?.map((item) => (
						<TreeItem
							readOnly
							key={item.id}
							item={item}
							level={0}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
						/>
					))}
					<Gap />
				</ScrollView>
			</Center>
			<View style={styles.modalFooter}>
				<TouchableOpacity
					style={[styles.button, styles.cancelButton]}
					// onPress={onClose}
				>
					<Text style={styles.buttonText}>Cancel</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[styles.button, styles.saveButton]}
					onPress={() => {
						// Handle save
						processWorkScopeFileThunk({ navigation: navigation });
					}}
				>
					<Text style={[styles.buttonText, styles.saveButtonText]}>
						Save Selection
					</Text>
				</TouchableOpacity>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 8,
		width: "80%",
		maxWidth: 600,
		maxHeight: "80%",
		padding: 20,
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "bold",
	},
	closeButton: {
		fontSize: 24,
		fontWeight: "bold",
		color: "#666",
	},
	toolsList: {
		marginTop: 20,
		maxHeight: 300,
	},
	toolItem: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#eee",
	},
	toolName: {
		marginLeft: 12,
		flex: 1,
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		// marginTop: 20,
		paddingTop: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
});

export default FilePreviewModal;
