import { action } from "easy-peasy";
import { updateHeaderInList } from "../../utilities/filter";

const actions = {
	setWorkScopeInputAction: action((state, payload) => {
		state.workscope.input = {
			...state.workscope.input,
			...payload,
		};
	}),
	setRemoveFileMappingAsAdminAreYouSureModalVisibleAction: action(
		(state, payload) => {
			state.workscope.removeFileMappingAsAdminAreYouSureModalVisible = payload;
		}
	),
	setWorkScopeListAction: action((state, workScopeList) => {
		state.workscope.list = workScopeList;
	}),
	setShowUploadWorkScopeFileModalAction: action((state, show) => {
		state.workscope.showUploadWorkScopeFileModal = show;
	}),
	setWorkScopeMasterToolListSearchInputAction: action((state, searchInput) => {
		state.workscope.workScopeMasterToolListSearchInput = searchInput;
	}),
	setCurrWorkScopeMasterToolListPageNumberAction: action(
		(state, pageNumber) => {
			state.workscope.currWorkScopeMasterToolListPageNumber = pageNumber;
		}
	),
	updateWorkScopeMasterToolHeaderAction: action(
		(state, { updatedTableHeader, parentHeaderIndex, subheaderIndex }) => {
			state.workscope.workScopeMasterToolHeaders = updateHeaderInList(
				updatedTableHeader,
				state.workscope.workScopeMasterToolHeaders,
				parentHeaderIndex,
				subheaderIndex
			);
		}
	),
	setWorkScopeSelectionOptionsAction: action((state, options) => {
		state.workscope.selectionOptions = options;
	}),
	setEngineModelInputAction: action((state, input) => {
		state.workscope.engineModelInput = input;
	}),
	setEngineInputAction: action((state, input) => {
		state.workscope.engineInput = input;
	}),
	setWorkScopeNameInputAction: action((state, input) => {
		state.workscope.workScopeNameInput = input;
	}),
	setWorkScopeFilePreviewAction: action((state, preview) => {
		state.workscope.workScopeFilePreview = preview;
	}),

	setFilePreviewModalVisibleAction: action((state, visible) => {
		state.workscope.filePreviewModalVisible = visible;
	}),

	setSelectedDataAction: action((state, selectedData) => {
		state.workscope.selectedData = selectedData;
	}),

	setWorkScopeToolListAction: action((state, toolList) => {
		state.workscope.toolList = toolList;
	}),

	setCurrentWorkScopeToolListPageNumberAction: action((state, pageNumber) => {
		state.workscope.currListPageNumber = pageNumber;
	}),

	setWorkScopeToolInputAction: action((state, payload) => {
		state.workscope.toolInput = {
			...state.workscope.toolInput,
			...payload,
		};
	}),

	setWorkScopeExpandableInputAction: action((state, payload) => {
		state.workscope.expandableInput = {
			...state.workscope.expandableInput,
			...payload,
		};
	}),
};
export default actions;
