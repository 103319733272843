import { StyleSheet, View } from "react-native";
import Text from "./Text";
import { vhToDp } from "../../utilities/responsive";

export const Breadcrumb = ({ items }) => {
	return (
		<View style={breadcrumbStyles.container}>
			{items.map((item, index) => (
				<View key={item.id} style={breadcrumbStyles.itemContainer}>
					<Text style={breadcrumbStyles.text} fontFamily="Barlow_600SemiBold">
						{item.name}
					</Text>
					{index < items.length - 1 && (
						<Text style={breadcrumbStyles.separator}> / </Text>
					)}
				</View>
			))}
		</View>
	);
};

// Add breadcrumb styles
const breadcrumbStyles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		padding: 10,
		borderRadius: 4,
	},
	itemContainer: {
		flexDirection: "row",
		alignItems: "center",
	},
	text: {
		fontSize: vhToDp(1.3),
		color: "#00205b",
		fontFamily: "Barlow_600SemiBold",
	},
	separator: {
		fontSize: 14,
		color: "#6c757d",
		marginHorizontal: 8,
	},
});
