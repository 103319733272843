// ReadOnlyTreeItem.js
import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";

/**
 * A read-only tree item that can still expand/collapse,
 * but no drag or selection logic.
 */
const ReadOnlyTreeItem = ({
	item,
	level,
	readOnlyExpandedIds,
	toggleReadOnlyExpanded,
}) => {
	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = readOnlyExpandedIds.has(item.id);

	return (
		<View style={{ marginLeft: level * 20 }}>
			<View style={styles.treeItemContainer}>
				{hasChildren && (
					<TouchableOpacity
						style={styles.expandButton}
						onPress={() => toggleReadOnlyExpanded(item.id)}
					>
						<Text style={styles.expandIcon}>{isExpanded ? "-" : "+"}</Text>
					</TouchableOpacity>
				)}
				<Text style={styles.itemText}>{item.name}</Text>
			</View>

			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<ReadOnlyTreeItem
							key={child.id}
							item={child}
							level={level + 1}
							readOnlyExpandedIds={readOnlyExpandedIds}
							toggleReadOnlyExpanded={toggleReadOnlyExpanded}
						/>
					))}
				</View>
			)}
		</View>
	);
};

export default ReadOnlyTreeItem;

const styles = StyleSheet.create({
	treeItemContainer: {
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
		borderBottomWidth: 1,
		borderBottomColor: "#f0f0f0",
	},
	expandButton: {
		padding: 4,
		marginRight: 8,
		width: 20,
		height: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	expandIcon: {
		fontSize: 18,
		fontWeight: "bold",
		color: "#666",
		lineHeight: 20,
	},
	itemText: {
		fontSize: 14,
		color: "#333",
	},
});
