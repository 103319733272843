import React, { useRef, useCallback, useEffect } from "react";
import {
	View,
	Text,
	Pressable,
	TouchableOpacity,
	Animated,
	PanResponder,
	Dimensions,
	Platform,
	StyleSheet,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const DragDropTreeItem = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
	onDragStart,
	onDragEnd,
	isDragging,
	potentialParentId,
	setPotentialParentId,
	positions,
	updatePositions,
	data,
	setData,
	scrollViewRef,
}) => {
	const dragHandleOffset = useRef({ x: 0, y: 0 });
	const itemRef = useRef(null);
	const pan = useRef(new Animated.ValueXY()).current;

	/**
	 * If level === 0 => top-level => NOT draggable/clickable
	 * If level > 0   => normal child => draggable & clickable
	 */
	const isTopLevel = level === 0;
	const canSelectOrDrag = !isTopLevel; // only child items (level > 0) can be selected/draggable

	// Expand logic (unchanged)
	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = expandedIds.has(item.id);

	// Selections
	const isSelected = selectedId === item.id;
	const isPotentialParent = potentialParentId === item.id;

	// measure layout
	const onLayoutItem = useCallback(() => {
		if (itemRef.current) {
			itemRef.current.measureInWindow((winX, winY, width, itemHeight) => {
				if (typeof winY === "number") {
					updatePositions(item.id, {
						pageY: winY,
						height: itemHeight,
						midPoint: winY + itemHeight / 2,
					});
				}
			});
		}
	}, [item.id, updatePositions]);

	useEffect(() => {
		onLayoutItem();
	}, [onLayoutItem]);

	// Identify the closest item for drop logic
	// const findClosestItem = (currentY) => {
	// 	let closest = null;
	// 	let minDistance = Infinity;
	// 	for (const [id, pos] of Object.entries(positions)) {
	// 		// skip self
	// 		if (id === String(item.id)) continue;
	// 		const distance = Math.abs(currentY - pos.midPoint);
	// 		if (distance < minDistance) {
	// 			minDistance = distance;
	// 			closest = id;
	// 		}
	// 	}
	// 	return minDistance < 40 ? closest : null;
	// };

	const findClosestItem = (currentY) => {
		const LARGE_THRESHOLD = 100; // Much larger threshold for detection
		let closest = null;

		Object.entries(positions).forEach(([id, pos]) => {
			// Skip self
			if (id === String(item.id)) return;

			// Simple distance check from the item's top
			const distanceFromTop = Math.abs(currentY - pos.pageY);

			// If we're within the threshold of the item's top
			if (distanceFromTop < LARGE_THRESHOLD) {
				closest = id;
			}
		});

		if (closest) {
			return closest;
		}

		return null;
	};
	// We'll define a PanResponder only if canSelectOrDrag is true
	// const panResponder = canSelectOrDrag
	// 	? PanResponder.create({
	// 			onStartShouldSetPanResponder: () => true,
	// 			onMoveShouldSetPanResponder: () => true,
	// 			onPanResponderTerminationRequest: () => false,

	// 			onPanResponderGrant: () => {
	// 				onDragStart(item.id);
	// 				pan.setOffset({
	// 					x: pan.x._value,
	// 					y: pan.y._value,
	// 				});
	// 			},

	// 			onPanResponderMove: (evt, gestureState) => {
	// 				const { pageY } = evt.nativeEvent;

	// 				// auto-scroll logic
	// 				if (Platform.OS === "web" && scrollViewRef?.current) {
	// 					const scrollNode = scrollViewRef.current.getScrollableNode
	// 						? scrollViewRef.current.getScrollableNode()
	// 						: null;
	// 					if (scrollNode) {
	// 						const rect = scrollNode.getBoundingClientRect();
	// 						const scrollThreshold = 80;
	// 						if (pageY < rect.top + scrollThreshold) {
	// 							scrollNode.scrollTop -= 10;
	// 						} else if (pageY > rect.bottom - scrollThreshold) {
	// 							scrollNode.scrollTop += 10;
	// 						}
	// 					}
	// 				} else {
	// 					const windowHeight = Dimensions.get("window").height;
	// 					const scrollThreshold = 80;
	// 					if (scrollViewRef?.current) {
	// 						if (pageY < scrollThreshold) {
	// 							scrollViewRef.current.scrollTo({
	// 								y: scrollViewRef.current.currentContentOffset - 10,
	// 								animated: false,
	// 							});
	// 						} else if (pageY > windowHeight - scrollThreshold) {
	// 							scrollViewRef.current.scrollTo({
	// 								y: scrollViewRef.current.currentContentOffset + 10,
	// 								animated: false,
	// 							});
	// 						}
	// 					}
	// 				}

	// 				// const closestId = findClosestItem(pageY);
	// 				// setPotentialParentId(closestId || null);

	// 				// pan.setValue({
	// 				// 	x: gestureState.dx,
	// 				// 	y: gestureState.dy,
	// 				// });
	// 				const closestId = findClosestItem(pageY);
	// 				if (closestId) {
	// 					setPotentialParentId(closestId);
	// 				} else {
	// 					setPotentialParentId(null);
	// 				}

	// 				pan.setValue({
	// 					x: gestureState.dx,
	// 					y: gestureState.dy,
	// 				});
	// 			},

	// 			onPanResponderRelease: () => {
	// 				pan.flattenOffset();
	// 				pan.setValue({ x: 0, y: 0 });
	// 				onDragEnd(item.id, potentialParentId);
	// 			},
	// 	  })
	// 	: null;
	const panResponder = canSelectOrDrag
		? PanResponder.create({
				onStartShouldSetPanResponder: () => true,
				onMoveShouldSetPanResponder: () => true,
				onPanResponderTerminationRequest: () => false,

				onPanResponderGrant: (evt) => {
					// Store the offset between touch position and handle position
					const { locationX, locationY } = evt.nativeEvent;
					dragHandleOffset.current = {
						x: locationX,
						y: locationY,
					};

					onDragStart(item.id);
					pan.setOffset({
						x: pan.x._value,
						y: pan.y._value,
					});
				},

				onPanResponderMove: (evt, gestureState) => {
					const { pageY } = evt.nativeEvent;

					// Adjust pageY by subtracting the initial offset
					const adjustedPageY = pageY - dragHandleOffset.current.y;

					// Auto-scroll logic stays the same...
					if (Platform.OS === "web" && scrollViewRef?.current) {
						const scrollNode = scrollViewRef.current.getScrollableNode
							? scrollViewRef.current.getScrollableNode()
							: null;
						if (scrollNode) {
							const rect = scrollNode.getBoundingClientRect();
							const scrollThreshold = 80;
							if (pageY < rect.top + scrollThreshold) {
								scrollNode.scrollTop -= 10;
							} else if (pageY > rect.bottom - scrollThreshold) {
								scrollNode.scrollTop += 10;
							}
						}
					}

					const closestId = findClosestItem(adjustedPageY);
					if (closestId) {
						setPotentialParentId(closestId);
					} else {
						setPotentialParentId(null);
					}

					pan.setValue({
						x: gestureState.dx,
						y: gestureState.dy,
					});
				},

				onPanResponderRelease: () => {
					pan.flattenOffset();
					pan.setValue({ x: 0, y: 0 });
					onDragEnd(item.id, potentialParentId);
					// Reset the offset
					dragHandleOffset.current = { x: 0, y: 0 };
				},
		  })
		: null;

	// Animated style for the item being dragged
	const animatedStyle = {
		transform: [
			{ translateX: isDragging === item.id ? pan.x : 0 },
			{ translateY: isDragging === item.id ? pan.y : 0 },
		],
		zIndex: isDragging === item.id ? 999 : 1,
	};

	/**
	 * We define 2 different render paths:
	 *  1. If canSelectOrDrag => Pressable + Drag icon
	 *  2. Otherwise => plain View (top-level item)
	 */
	const renderContent = () => {
		const containerStyles = [
			stylesDragDrop.treeItemContainer,
			isPotentialParent && stylesDragDrop.dropTarget,
			isDragging === item.id && stylesDragDrop.draggingItem,
		];

		if (canSelectOrDrag) {
			// This is a child, so user can click + drag
			return (
				<Pressable
					onPress={() => setSelectedId(item.id)}
					style={[
						...containerStyles,
						isSelected && stylesDragDrop.selectedItem,
					]}
				>
					<Animated.View style={animatedStyle}>
						<View style={stylesDragDrop.itemInner}>
							{/* Expand button if hasChildren */}
							{hasChildren && (
								<TouchableOpacity
									style={stylesDragDrop.expandButton}
									onPress={() => toggleExpanded(item.id)}
								>
									<Text style={stylesDragDrop.expandIcon}>
										{isExpanded ? "-" : "+"}
									</Text>
								</TouchableOpacity>
							)}

							<Text
								style={[
									stylesDragDrop.itemText,
									isSelected && stylesDragDrop.selectedText,
								]}
							>
								{item.name}
							</Text>

							{/* Drag handle */}
							<View
								{...(panResponder ? panResponder.panHandlers : {})}
								style={[
									stylesDragDrop.dragHandle,
									isDragging === item.id && stylesDragDrop.dragHandleDragging,
								]}
							>
								<MaterialCommunityIcons name="drag" size={24} color="#666" />
							</View>
						</View>
					</Animated.View>
				</Pressable>
			);
		} else {
			// This is a top-level item => no selection, no drag
			return (
				<View style={containerStyles}>
					<Animated.View style={animatedStyle}>
						<View style={stylesDragDrop.itemInner}>
							{hasChildren && (
								<TouchableOpacity
									style={stylesDragDrop.expandButton}
									onPress={() => toggleExpanded(item.id)}
								>
									<Text style={stylesDragDrop.expandIcon}>
										{isExpanded ? "-" : "+"}
									</Text>
								</TouchableOpacity>
							)}
							<Text style={stylesDragDrop.itemText}>{item.name}</Text>
							{/* no drag icon */}
						</View>
					</Animated.View>
				</View>
			);
		}
	};

	return (
		<View
			ref={itemRef}
			onLayout={onLayoutItem}
			style={{ marginLeft: level * 20 }}
		>
			{renderContent()}

			{/* Render children if expanded */}
			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<DragDropTreeItem
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
							onDragStart={onDragStart}
							onDragEnd={onDragEnd}
							isDragging={isDragging}
							potentialParentId={potentialParentId}
							setPotentialParentId={setPotentialParentId}
							positions={positions}
							updatePositions={updatePositions}
							data={data}
							setData={setData}
							scrollViewRef={scrollViewRef}
						/>
					))}
				</View>
			)}
		</View>
	);
};

export default DragDropTreeItem;

/** Styles */
const stylesDragDrop = StyleSheet.create({
	treeItemContainer: {
		width: "100%",
		backgroundColor: "#fff",
		borderRadius: 4,
		marginVertical: 1,
		borderWidth: 1,
		borderColor: "#e0e0e0",
	},
	itemInner: {
		flexDirection: "row",
		alignItems: "center",
		padding: 10,
	},
	expandButton: {
		width: 24,
		height: 24,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
	},
	expandIcon: {
		fontSize: 17,
		color: "#444",
	},
	itemText: {
		fontSize: 14,
		color: "#333",
		flex: 1,
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	dragHandle: {
		width: 40,
		height: 40,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#f0f0f0",
		borderRadius: 4,
		marginLeft: 8,
	},
	dragHandleDragging: {
		backgroundColor: "#bbdefb",
	},
	draggingItem: {
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	dropTarget: {
		backgroundColor: "#cce7f5",
		borderColor: "#80c4e7",
		borderWidth: 2,
		borderStyle: "dashed",
	},
});
