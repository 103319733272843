// ArrangementModal.js
import React from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	StyleSheet,
} from "react-native";
import { width, height } from "../../../utilities/general";
import ReadOnlyTreeItem from "../DragDropTree/ReadOnlyTreeItem";
import Modal from "../../functional/Modal";

const ArrangementModal = ({
	visible,
	changes,
	onClose,
	onSave,
	onUndo,
	loading,
	data,
	readOnlyExpandedIds,
	toggleReadOnlyExpanded,
}) => {
	return (
		<Modal
			title="Review Arrangement Changes"
			isVisible={visible}
			onClose={onClose}
		>
			{changes.length === 0 ? (
				<Text>No changes detected.</Text>
			) : (
				<View style={{ maxHeight: 300 }}>
					<ScrollView
						style={{ width: "100%", height: "100%" }}
						contentContainerStyle={{
							width: width / 2,
							height: height / 2,
						}}
					>
						{/* Render entire tree in read-only form */}
						{data?.map((item) => (
							<ReadOnlyTreeItem
								key={item.id}
								item={item}
								level={0}
								readOnlyExpandedIds={readOnlyExpandedIds}
								toggleReadOnlyExpanded={toggleReadOnlyExpanded}
							/>
						))}
					</ScrollView>
				</View>
			)}

			<View style={styles.modalFooter}>
				<TouchableOpacity
					style={[styles.button, styles.cancelButton]}
					onPress={onUndo}
					disabled={loading}
				>
					<Text style={styles.buttonText}>Undo</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[styles.button, styles.saveButton]}
					onPress={onSave}
					disabled={loading}
				>
					<Text style={[styles.buttonText, styles.saveButtonText]}>
						{loading ? "Saving..." : "Save"}
					</Text>
				</TouchableOpacity>
			</View>
		</Modal>
	);
};

export default ArrangementModal;

const styles = StyleSheet.create({
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		paddingTop: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
});
