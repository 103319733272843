import { Center } from "@cfbs/cfbsstrap-native";
import { useUploadWorkScopeFileModal } from "../../../hooks/workscope";
import Button from "../../presentational/Button";
import Modal from "./Modal";
import Gap from "../../presentational/Gap";
import Col from "../../presentational/Col";
import SelectBox from "react-native-multi-selectbox";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { xorBy } from "lodash";
import Input from "../../presentational/Input";

function UploadWorkScopeFileModal(props) {
	const { downloadButton, uploadButton, ...modalProps } =
		useUploadWorkScopeFileModal();

	const {
		file: { engineVariantList, engineFamilyList },
		workscope: { input },
	} = useStoreState((state) => state);
	const { setWorkScopeInputAction } = useStoreActions((actions) => actions);

	return (
		<Modal {...modalProps} {...props}>
			<Center>
				<Col>
					<Input
						width="99%"
						label="Engine Model"
						placeholder="Enter Engine Model"
						onSelect={(name) => {
							setWorkScopeInputAction({
								enginefamily: name.EngineFamily,
								enginefamilyid: name.EngineFamilyID,
							});
						}}
						value={input?.enginefamily}
						// uploadEngineModel}
						required
						editable={true}
						dropdown
						dropdownChoices={engineFamilyList}
						selectedItem={(item) => item.EngineFamily}
						rowTextForSelection={(item) => item.EngineFamily}
					/>
				</Col>
				<Col>
					<Input
						width="99%"
						label="Engine Variant"
						placeholder="Enter Engine Variant"
						onSelect={(name) => {
							setWorkScopeInputAction({
								enginevariant: name.EngineVariant,
								enginevariantid: name.EngineVariantID,
							});
						}}
						value={input?.enginevariant}
						required
						editable={input.enginefamilyid ? true : false}
						dropdown
						dropdownChoices={engineVariantList.filter(
							(item) => item.EngineFamilyID == input.enginefamilyid
						)}
						selectedItem={(item) => item.EngineVariant}
						rowTextForSelection={(item) => item.EngineVariant}
					/>
				</Col>
				<Col>
					<Input
						width="99%"
						label="WS Parent (Optional)"
						placeholder="Enter WS Parent"
						onSelect={(name) => {
							setWorkScopeInputAction({
								enginevariant: name.EngineVariant,
								enginevariantid: name.EngineVariantID,
							});
						}}
						value={""}
						editable={true}
						dropdown
						dropdownChoices={engineVariantList.filter(
							(item) => item.EngineFamilyID == input.enginefamilyid
						)}
						selectedItem={(item) => item.EngineVariant}
						rowTextForSelection={(item) => item.EngineVariant}
					/>
				</Col>
				<Button {...downloadButton.props}>{downloadButton.children}</Button>
				<Gap />
				<Button {...uploadButton.props}>{uploadButton.children}</Button>
			</Center>
		</Modal>
	);
}

export default UploadWorkScopeFileModal;
