import GroupedList from "./GroupedList";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForComparison } from "../../../utilities/string";
import { useTableFilter } from "../../../hooks/filter";
import { useEffect, useState } from "react";
import Text from "../../presentational/Text";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

function useTable(setToolModalVisible) {
	const firstPageNum = 1;

	const {
		toolList: list,
		toolHeaders: headers,
		currListPageNumber,
	} = useStoreState((state) => state.workscope);

	const {
		setCurrentWorkScopeToolListPageNumberAction,
		deleteWorkScopeToolThunk,
	} = useStoreActions((actions) => actions);

	function reloadListByPageNumber(pageNumber) {
		setFilteredCopyList(filterList());
		setCurrentWorkScopeToolListPageNumberAction(pageNumber);
	}

	const { filteredList: sortedList, ...tableProps } = useTableFilter({
		headers,
		list: list.map((pn) => ({ ...pn, numofpages: totalPageNum })),
		reloadListByPageNumberFunction: reloadListByPageNumber,
		currPageNumber: currListPageNumber,
		setPageNumberFunction: setCurrentWorkScopeToolListPageNumberAction,
	});

	const [filteredCopyList, setFilteredCopyList] = useState(sortedList.slice());
	const [isFiltering, setIsFiltering] = useState(false);

	const pageSize = 5;
	const totalPageNum = Math.ceil(filteredCopyList.length / pageSize) || 1;

	function filterList() {
		return sortedList
			.filter((pn) =>
				headers
					.flatMap((header) => header.subheaders || header)
					.every((header) => {
						const value = (pn[header.propertyName] || "").toLowerCase();

						if (header.isCheckingEqual) {
							return value === header.filterValue.toLowerCase();
						} else if (header.isCheckingContains) {
							return value.includes(header.filterValue.toLowerCase());
						}

						return true;
					})
			)
			.slice();
	}

	useEffect(() => {
		if (isFiltering) {
			setFilteredCopyList(filterList());
			setIsFiltering(false);
		}
	}, [isFiltering]);

	useEffect(() => {
		setFilteredCopyList(filterList());
	}, [
		JSON.stringify(
			headers
				.flatMap((header) => header.subheaders || header)
				.map((header) => ({
					isSortAscending: header.isSortAscending,
					isSortDescending: header.isSortDescending,
					isCheckingEqual: header.isCheckingEqual,
					isCheckingContains: header.isCheckingContains,
				}))
		),
		list,
	]);

	return {
		cellFontSize: 20,
		headerFontSize: 18,
		list: filteredCopyList

			.slice(
				0 + currListPageNumber * pageSize - pageSize,
				0 + currListPageNumber * pageSize
			)
			.map((item) => ({
				...item,
				partNumber: (
					<TouchableOpacity
						onPress={() => setToolModalVisible(item.partNumber)}
					>
						<Text style={{ fontWeight: "bold" }}>{item.partNumber}</Text>
					</TouchableOpacity>
				),
				delete: (
					<TouchableOpacity
						onPress={() => {
							deleteWorkScopeToolThunk(item.id);
						}}
					>
						<Ionicons name="trash-outline" size={24} color="black" />
					</TouchableOpacity>
				),
				numofpages: totalPageNum,
			})),
		...tableProps,
		totalPageNum,
		onPressOk: (evt) => {
			setIsFiltering(true);
			tableProps.filterEvents.onPressOk(evt);
		},
		onPressLastPage: () => reloadListByPageNumber(totalPageNum),
		onChangePageNum: (text) => {
			const currPageNum = Math.min(
				parseInt(text) || firstPageNum,
				totalPageNum
			);
			setCurrentWorkScopeToolListPageNumberAction(
				Math.max(firstPageNum, currPageNum)
			);
		},
	};
}

function WorkScopeToolList({ setToolModalVisible, ...props }) {
	const listProps = useTable(setToolModalVisible);
	return <GroupedList {...listProps} {...props} />;
}

export default WorkScopeToolList;
