import React, { useState } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	StyleSheet,
	ScrollView,
} from "react-native";
import { CheckBox } from "@rneui/themed";
import Form from "../Form";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import { Divider } from "react-native-elements";
import { FontAwesome5 } from "@expo/vector-icons";
import { remToDp } from "@cfbs/cfbsstrap-native";
import { useStoreState } from "easy-peasy";

const ToolSelectionModal = ({ visible, onClose, onSave }) => {
	const { engineVariantList, engineFamilyList } = useStoreState(
		(state) => state.file
	);
	const [selectedTools, setSelectedTools] = useState([]);

	const handleToggleTool = (toolId) => {
		console.log("toolId", toolId);
		setSelectedTools((prev) => {
			console.log("prev", prev);
			if (prev.includes(toolId)) {
				console.log(
					"prev.filter",
					prev.filter((id) => id !== toolId)
				);
				return prev.filter((id) => id !== toolId);
			} else {
				return [...prev, toolId];
			}
		});
	};

	return (
		<Modal visible={visible} animationType="slide" transparent={true}>
			<View style={styles.modalContainer}>
				<View style={styles.modalContent}>
					<View style={styles.modalHeader}>
						<Text style={styles.modalTitle}>Select Engines</Text>
						<TouchableOpacity onPress={onClose}>
							<Text style={styles.closeButton}>×</Text>
						</TouchableOpacity>
					</View>
					<Divider />

					<ScrollView style={styles.toolsList}>
						{engineVariantList.map((tool) => (
							<TouchableOpacity
								key={tool.EngineVariantID}
								style={styles.toolItem}
								onPress={() => handleToggleTool(tool.EngineVariantID)}
							>
								<CheckBox
									checked={selectedTools.includes(tool.EngineVariantID)}
									onChange={() => handleToggleTool(tool.EngineVariantID)}
									checkedIcon={
										<FontAwesome5
											name="check-square"
											size={remToDp(1.5)}
											color="#007AFF"
										/>
									}
								/>
								<Text style={styles.toolName}>
									{
										// find the engineFamily Name from the engineFamilyList if the engineFamilyID matches the tool.EngineFamilyID
										engineFamilyList.find(
											(e) => e.EngineFamilyID == tool.EngineFamilyID
										).EngineFamily +
											" - " +
											tool.EngineVariant
									}
								</Text>
							</TouchableOpacity>
						))}
					</ScrollView>

					<View style={styles.modalFooter}>
						<TouchableOpacity
							style={[styles.button, styles.cancelButton]}
							onPress={onClose}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.button, styles.saveButton]}
							onPress={() => {
								// Handle save
								onSave(selectedTools);
							}}
						>
							<Text style={[styles.buttonText, styles.saveButtonText]}>
								Save Selection
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 8,
		width: "80%",
		maxWidth: 600,
		maxHeight: "80%",
		padding: 20,
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "bold",
	},
	closeButton: {
		fontSize: 24,
		fontWeight: "bold",
		color: "#666",
	},
	toolsList: {
		marginTop: 20,
		maxHeight: 300,
	},
	toolItem: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#eee",
	},
	toolName: {
		marginLeft: 12,
		flex: 1,
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: 20,
		paddingTop: 20,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
});

export default ToolSelectionModal;
