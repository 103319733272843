import React, { useEffect, useState, useRef, useCallback } from "react";
import {
	View,
	Text,
	StyleSheet,
	Animated,
	PanResponder,
	ScrollView,
	TouchableOpacity,
	Pressable,
	Platform,
	Dimensions,
	ActivityIndicator,
} from "react-native";
import styled from "styled-components/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Divider } from "react-native-elements";
import { useStoreActions, useStoreState } from "easy-peasy";

import Form from "../containers/Form";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import Input from "../presentational/Input";
import Switch from "../presentational/Switch";
import Button from "../presentational/Button";
import Gap from "../presentational/Gap";
import Modal from "../functional/Modal";
import ToolSelectionModal from "../containers/Modal/TestSelectionModal";
import WorkScopeToolList from "../functional/List/WorkScopeToolList";
import Center from "../presentational/Center";
import TextPresentational from "../presentational/Text";
import TreeItem from "../presentational/Tree/TreeItem";
import { height, width } from "../../utilities/general";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LogLevel } from "@microsoft/signalr";

/**
 * Minimal "Page" wrapper
 */
const Page = ({ children, style }) => {
	return (
		<SafeAreaView style={[styles.pageContainer, style]}>
			{children}
		</SafeAreaView>
	);
};

// Styled Label
const Label = styled(Text)`
	color: #00205b;
`;

/**
 * Read-Only TreeItem used in the modal,
 * with its own expand/collapse logic via readOnlyExpandedIds
 */
const ReadOnlyTreeItem = ({
	item,
	level,
	readOnlyExpandedIds,
	toggleReadOnlyExpanded,
}) => {
	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = readOnlyExpandedIds.has(item.id);

	return (
		<View style={{ marginLeft: level * 20 }}>
			<View style={stylesReadOnly.treeItemContainer}>
				{/* Expand/Collapse */}
				{hasChildren && (
					<TouchableOpacity
						style={stylesReadOnly.expandButton}
						onPress={() => toggleReadOnlyExpanded(item.id)}
					>
						<Text style={stylesReadOnly.expandIcon}>
							{isExpanded ? "-" : "+"}
						</Text>
					</TouchableOpacity>
				)}

				{/* Label (no drag handle) */}
				<Text style={stylesReadOnly.itemText}>{item.name}</Text>
			</View>

			{/* Render children if expanded */}
			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<ReadOnlyTreeItem
							key={child.id}
							item={child}
							level={level + 1}
							readOnlyExpandedIds={readOnlyExpandedIds}
							toggleReadOnlyExpanded={toggleReadOnlyExpanded}
						/>
					))}
				</View>
			)}
		</View>
	);
};

/**
 * Modal that shows the read-only tree,
 * using a *separate* expanded set (readOnlyExpandedIds)
 */
const ArrangementModal = ({
	visible,
	changes,
	onClose,
	onSave,
	onUndo,
	loading,
	data,

	// read-only modal expanded
	readOnlyExpandedIds,
	toggleReadOnlyExpanded,
}) => {
	return (
		<Modal
			title="Review Arrangement Changes"
			isVisible={visible}
			onClose={onClose}
		>
			{changes.length === 0 ? (
				<Text>No changes detected.</Text>
			) : (
				<View style={{ maxHeight: 300 }}>
					<ScrollView
						style={{ width: "100%", height: "100%" }}
						contentContainerStyle={{
							width: width / 2, // or remove if you want it to autosize
							height: height / 2, // or remove
						}}
					>
						{/* We now show the entire data as a read-only tree */}
						{data?.map((item) => (
							<ReadOnlyTreeItem
								key={item.id}
								item={item}
								level={0}
								readOnlyExpandedIds={readOnlyExpandedIds}
								toggleReadOnlyExpanded={toggleReadOnlyExpanded}
							/>
						))}
					</ScrollView>
				</View>
			)}

			<View style={styles.modalFooter}>
				<TouchableOpacity
					style={[styles.button, styles.cancelButton]}
					onPress={onUndo}
					disabled={loading}
				>
					<Text style={styles.buttonText}>Undo</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[styles.button, styles.saveButton]}
					onPress={onSave}
					disabled={loading}
				>
					<Text style={[styles.buttonText, styles.saveButtonText]}>
						{loading ? "Saving..." : "Save"}
					</Text>
				</TouchableOpacity>
			</View>
		</Modal>
	);
};

/**
 * Drag-Drop Tree Item (original)
 */
const DragDropTreeItem = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
	onDragStart,
	onDragEnd,
	isDragging,
	potentialParentId,
	setPotentialParentId,
	positions,
	updatePositions,
	data,
	setData,
	scrollViewRef,
}) => {
	const itemRef = useRef(null);
	const pan = useRef(new Animated.ValueXY()).current;

	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = expandedIds.has(item.id);
	const isSelected = selectedId === item.id;
	const isPotentialParent = potentialParentId === item.id;

	// measure layout
	const onLayoutItem = useCallback(() => {
		if (itemRef.current) {
			itemRef.current.measureInWindow((winX, winY, width, itemHeight) => {
				if (typeof winY !== "number") return;
				updatePositions(item.id, {
					pageY: winY,
					height: itemHeight,
					midPoint: winY + itemHeight / 2,
				});
			});
		}
	}, [item.id, updatePositions]);

	useEffect(() => {
		onLayoutItem();
	}, [onLayoutItem]);

	// find the item whose midpoint is closest
	const findClosestItem = (currentY) => {
		let closest = null;
		let minDistance = Infinity;
		Object.entries(positions).forEach(([id, pos]) => {
			if (id === String(item.id)) return; // skip self
			const distance = Math.abs(currentY - pos.midPoint);
			if (distance < minDistance) {
				minDistance = distance;
				closest = id;
			}
		});
		return minDistance < 40 ? closest : null;
	};

	// PanResponder
	const panResponder = PanResponder.create({
		onStartShouldSetPanResponder: () => true,
		onMoveShouldSetPanResponder: () => true,
		onPanResponderTerminationRequest: () => false,

		onPanResponderGrant: () => {
			onDragStart(item.id);
			pan.setOffset({
				x: pan.x._value,
				y: pan.y._value,
			});
		},

		onPanResponderMove: (evt, gestureState) => {
			const { pageY } = evt.nativeEvent;

			// web auto-scroll
			if (Platform.OS === "web" && scrollViewRef?.current) {
				const scrollNode = scrollViewRef.current.getScrollableNode
					? scrollViewRef.current.getScrollableNode()
					: null;
				if (scrollNode) {
					const rect = scrollNode.getBoundingClientRect();
					const scrollThreshold = 80;
					if (pageY < rect.top + scrollThreshold) {
						scrollNode.scrollTop -= 10;
					} else if (pageY > rect.bottom - scrollThreshold) {
						scrollNode.scrollTop += 10;
					}
				}
			} else {
				// native auto-scroll
				const windowHeight = Dimensions.get("window").height;
				const scrollThreshold = 80;
				if (scrollViewRef?.current) {
					if (pageY < scrollThreshold) {
						scrollViewRef.current.scrollTo({
							y: scrollViewRef.current.currentContentOffset - 10,
							animated: false,
						});
					} else if (pageY > windowHeight - scrollThreshold) {
						scrollViewRef.current.scrollTo({
							y: scrollViewRef.current.currentContentOffset + 10,
							animated: false,
						});
					}
				}
			}

			// find potential parent
			const closestId = findClosestItem(pageY);
			setPotentialParentId(closestId || null);

			pan.setValue({
				x: gestureState.dx,
				y: gestureState.dy,
			});
		},

		onPanResponderRelease: () => {
			pan.flattenOffset();
			pan.setValue({ x: 0, y: 0 });
			onDragEnd(item.id, potentialParentId);
		},
	});

	const animatedStyle = {
		transform: [
			{ translateX: isDragging === item.id ? pan.x : 0 },
			{ translateY: isDragging === item.id ? pan.y : 0 },
		],
		zIndex: isDragging === item.id ? 999 : 1,
	};

	return (
		<View
			ref={itemRef}
			onLayout={onLayoutItem}
			style={{ marginLeft: level * 20 }}
		>
			<Pressable
				onPress={() => setSelectedId(item.id)}
				style={[
					stylesDragDrop.treeItemContainer,
					isSelected && stylesDragDrop.selectedItem,
					isPotentialParent && stylesDragDrop.dropTarget,
					isDragging === item.id && stylesDragDrop.draggingItem,
				]}
			>
				<Animated.View style={animatedStyle}>
					<View style={stylesDragDrop.itemInner}>
						{/* Expand/Collapse */}
						{hasChildren && (
							<TouchableOpacity
								style={stylesDragDrop.expandButton}
								onPress={() => toggleExpanded(item.id)}
							>
								<Text style={stylesDragDrop.expandIcon}>
									{isExpanded ? "-" : "+"}
								</Text>
							</TouchableOpacity>
						)}
						{/* Label */}
						<Text
							style={[
								stylesDragDrop.itemText,
								isSelected && stylesDragDrop.selectedText,
							]}
						>
							{item.name}
						</Text>

						{/* Drag Handle */}
						<View
							{...panResponder.panHandlers}
							style={[
								stylesDragDrop.dragHandle,
								isDragging === item.id && stylesDragDrop.dragHandleDragging,
							]}
						>
							<MaterialCommunityIcons name="drag" size={24} color="#666" />
						</View>
					</View>
				</Animated.View>
			</Pressable>

			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<DragDropTreeItem
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
							onDragStart={onDragStart}
							onDragEnd={onDragEnd}
							isDragging={isDragging}
							potentialParentId={potentialParentId}
							setPotentialParentId={setPotentialParentId}
							positions={positions}
							updatePositions={updatePositions}
							data={data}
							setData={setData}
							scrollViewRef={scrollViewRef}
						/>
					))}
				</View>
			)}
		</View>
	);
};

/** ContentView (details form) */
const ContentView = ({ selectedItem }) => {
	const {
		workscope: { input, toolInput, list, toolList },
		workpackage: { list: wpList },
		file: { engineFamilyList, engineVariantList },
	} = useStoreState((state) => state);

	const {
		getWorkScopeToolsThunk,
		setWorkScopeInputAction,
		setWorkScopeToolInputAction,
		upsertWorkScopeToolThunk,
		upsertWorkScopeThunk,
		deleteWorkScopeThunk,
	} = useStoreActions((actions) => actions);

	const [toolModalVisible, setToolSelectionModalVisible] = useState(false);

	useEffect(() => {
		if (!selectedItem) return;
		if (input.id !== selectedItem.id) {
			const selectedData = list.find((ws) => ws.id === selectedItem.id);
			if (selectedData) {
				setWorkScopeInputAction(selectedData);
				getWorkScopeToolsThunk({ id: selectedItem.id });
			}
		}
	}, [
		selectedItem,
		list,
		input.id,
		setWorkScopeInputAction,
		getWorkScopeToolsThunk,
	]);

	return (
		<View style={styles.contentContainer}>
			<Modal
				title="Add Work Scope Tool"
				isVisible={toolModalVisible}
				onClose={() => setToolSelectionModalVisible(false)}
			>
				<Row>
					<Col>
						<Input
							label="Part Number"
							onChangeText={(partnumber) =>
								setWorkScopeToolInputAction({ partnumber })
							}
							value={toolInput.partnumber}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Input
							label="Tool Nomenclature"
							onChangeText={(toolnomenclature) =>
								setWorkScopeToolInputAction({ toolnomenclature })
							}
							value={toolInput.toolnomenclature}
							required
						/>
					</Col>
				</Row>

				<View style={styles.modalFooter}>
					<TouchableOpacity style={[styles.button, styles.cancelButton]}>
						<Text style={styles.buttonText}>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.button, styles.saveButton]}
						onPress={() => {
							upsertWorkScopeToolThunk();
						}}
					>
						<Text style={[styles.buttonText, styles.saveButtonText]}>
							Save Selection
						</Text>
					</TouchableOpacity>
				</View>
			</Modal>

			<View style={styles.breadcrumbContainer}>
				<Divider />
			</View>

			{selectedItem ? (
				<Form>
					<Row>
						<Col xs={12} md={8}>
							<Input
								label="Work Scope Name"
								onChangeText={(workscopename) =>
									setWorkScopeInputAction({ workscopename })
								}
								value={input.workscopename}
								required
							/>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									deleteWorkScopeThunk();
								}}
								color="#ab2328"
								fullWidth
							>
								Delete
							</Button>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									upsertWorkScopeThunk();
								}}
								color="#1976d2"
								fullWidth
							>
								Save
							</Button>
						</Col>
					</Row>

					<Row>
						<Col>
							<Input
								label="Work Scope Description"
								onChangeText={(workscopedescription) =>
									setWorkScopeInputAction({ workscopedescription })
								}
								value={input.workscopedescription}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4}>
							<Input
								label="Work Scope Parent Key"
								onChangeText={(wsparentid) =>
									setWorkScopeInputAction({ wsparentid })
								}
								value={input.wsparentid}
								required
								editable={false}
							/>
						</Col>
						<Col xs={12} md={5}>
							<Input
								label="Work Scope Parent Name"
								onSelect={(wsparentid) =>
									setWorkScopeInputAction({ wsparentid })
								}
								// value={input.wsparentid}
								value={
									list.find((ws) => ws.id === input.wsparentid)?.workscopename
								}
								required
								editable={false}
								dropdownChoices={
									// map through the list and only show the name of the level 1 items
									list
										.filter((ws) => ws.level === 1)
										.map((ws) => ({
											id: ws.id,
											name: ws.workscopename,
										}))
								}
							/>
						</Col>
						<Col xs={12} md={3}>
							<Input
								label="Work Scope Order"
								onChangeText={(ordernum) =>
									setWorkScopeInputAction({ ordernum })
								}
								value={input.ordernum}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={3}>
							<Input
								label="Work Package"
								value={
									input.workpackageid
										? wpList?.find((w) => w.id === input.workpackageid)
												?.description
										: "N/A"
								}
								onSelect={(item) => {
									setWorkScopeInputAction({
										workpackageid: item.id,
										wpdescription: item.description,
									});
								}}
								required
								dropdown
								dropdownChoices={wpList}
								selectedItem={(item) => item.description}
								rowTextForSelection={(item) => item.description}
							/>
						</Col>
						<Col xs={12} md={9}>
							<Input
								editable={false}
								label="Work Package Description"
								onChangeText={(wpdescription) =>
									setWorkScopeInputAction({ wpdescription })
								}
								value={input.wpdescription || "N/A"}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<Label>Notes</Label>
							<Input
								multiline
								numberOfLines={4}
								onChangeText={(notes) => setWorkScopeInputAction({ notes })}
								value={input.notes}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<View style={styles.toolsHeader}>
								<Label>Tools</Label>
								<TouchableOpacity
									style={styles.addButton}
									onPress={() => setToolSelectionModalVisible(true)}
								>
									<Text style={styles.addButtonText}>Add</Text>
								</TouchableOpacity>
							</View>
							<WorkScopeToolList />
						</Col>
					</Row>
				</Form>
			) : (
				<Text style={styles.noContentText}>Select an item to view details</Text>
			)}
		</View>
	);
};

// --------------
// Main TreeView
// --------------
const TreeView = () => {
	const scrollViewRef = useRef(null);

	// local states
	const [data, setData] = useState([]);
	const [backupData, setBackupData] = useState(null);
	const [selectedId, setSelectedId] = useState(null);

	// expanded state for the main (left) tree
	const [expandedIds, setExpandedIds] = useState(new Set());

	// expanded state for the read-only modal
	const [readOnlyExpandedIds, setReadOnlyExpandedIds] = useState(new Set());

	// Drag states
	const [draggingId, setDraggingId] = useState(null);
	const [potentialParentId, setPotentialParentId] = useState(null);

	// Positions: { [id]: { pageY, height, midPoint } }
	const [positions, setPositions] = useState({});

	const [scrollEnabled, setScrollEnabled] = useState(true);

	// track changes
	const [changes, setChanges] = useState([]);
	const [showArrangementModal, setShowArrangementModal] = useState(false);
	const [loadingArrangement, setLoadingArrangement] = useState(false);

	// store
	const { workscope } = useStoreState((state) => state);
	const { getWorkScopeThunk, getWorkPackageThunk, upsertWorkScopeThunk } =
		useStoreActions((actions) => actions);

	useEffect(() => {
		getWorkScopeThunk({ enginefamilyid: 1, enginevariantid: 1 });
		getWorkPackageThunk({});
	}, [getWorkScopeThunk, getWorkPackageThunk]);

	useEffect(() => {
		if (Array.isArray(workscope.selectedData)) {
			setData(workscope.selectedData);
		}
	}, [workscope.selectedData]);

	// expand togglers
	const toggleExpanded = (id) => {
		setExpandedIds((prev) => {
			const next = new Set(prev);
			next.has(id) ? next.delete(id) : next.add(id);
			return next;
		});
	};
	const toggleReadOnlyExpanded = (id) => {
		setReadOnlyExpandedIds((prev) => {
			const next = new Set(prev);
			next.has(id) ? next.delete(id) : next.add(id);
			return next;
		});
	};

	// scroll
	const handleScroll = (evt) => {
		const offsetY = evt.nativeEvent.contentOffset.y;
		if (scrollViewRef.current) {
			scrollViewRef.current.currentContentOffset = offsetY;
		}
	};

	const findSelectedItem = (items, id) => {
		for (const node of items) {
			if (node.id === id) return node;
			if (node.children) {
				const found = findSelectedItem(node.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	const updatePositions = (id, position) => {
		setPositions((prev) => ({ ...prev, [id]: position }));
	};

	const handleDragStart = (id) => {
		setBackupData(JSON.parse(JSON.stringify(data)));
		setDraggingId(id);
		setScrollEnabled(false);
	};

	const handleDragEnd = (draggedId, dropTargetId) => {
		setDraggingId(null);
		setPotentialParentId(null);
		setScrollEnabled(true);

		if (draggedId === dropTargetId || dropTargetId === undefined) {
			if (backupData) setData(backupData);
			return;
		}
		if (!dropTargetId) {
			if (backupData) setData(backupData);
			return;
		}

		const draggedItem = findItemById(data, draggedId);
		if (!draggedItem) return;

		let newData = removeItemById(data, draggedId);
		newData = addToTarget(newData, dropTargetId, draggedItem);

		if (!expandedIds.has(dropTargetId)) {
			setExpandedIds((prev) => {
				const next = new Set(prev);
				next.add(dropTargetId);
				return next;
			});
		}

		setData(newData);

		const updatedItem = {
			...draggedItem,
			wsparentid: dropTargetId,
		};
		setChanges((prev) => {
			const filtered = prev.filter((c) => c.id !== updatedItem.id);
			return [...filtered, updatedItem];
		});
	};

	const removeItemById = (items, id) =>
		items
			.map((itm) => {
				if (itm.id === id) return null;
				if (itm.children?.length) {
					return {
						...itm,
						children: removeItemById(itm.children, id).filter(Boolean),
					};
				}
				return itm;
			})
			.filter(Boolean);

	const findItemById = (items, id) => {
		for (const itm of items) {
			if (itm.id === id) return itm;
			if (itm.children?.length) {
				const found = findItemById(itm.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	const addToTarget = (items, targetId, draggedItem) => {
		return items.map((node) => {
			if (node.id === targetId) {
				return {
					...node,
					children: [...(node.children || []), draggedItem],
				};
			}
			if (node.children?.length) {
				return {
					...node,
					children: addToTarget(node.children, targetId, draggedItem),
				};
			}
			return node;
		});
	};

	// review changes => show the modal
	const handleReviewChanges = () => {
		setShowArrangementModal(true);

		// optionally, auto-expand everything in the modal:
		// but we can let user expand as they want
		// for example:
		// if you want everything open by default:
		// let allIds = new Set();
		// const gatherIds = (nodes) => {
		//   for (const n of nodes) {
		//     allIds.add(n.id);
		//     if (n.children?.length) gatherIds(n.children);
		//   }
		// };
		// gatherIds(data);
		// setReadOnlyExpandedIds(allIds);
	};

	const handleUndoAll = () => {
		if (backupData) setData(backupData);
		setChanges([]);
		setShowArrangementModal(false);
	};

	const handleSaveArrangement = async () => {
		setLoadingArrangement(true);
		try {
			for (const changedItem of changes) {
				await upsertWorkScopeThunk({
					...workscope.list.find((ws) => ws.id === changedItem.id),
					wsparentid: changedItem.wsparentid,
				});
			}
			console.log("Arrangement saved. Items updated:", changes);
			setChanges([]);
			setShowArrangementModal(false);
		} catch (err) {
			console.log("Failed saving arrangement:", err);
		} finally {
			setLoadingArrangement(false);
		}
	};

	return (
		<Page style={styles.container}>
			<ArrangementModal
				visible={showArrangementModal}
				changes={changes}
				onClose={() => setShowArrangementModal(false)}
				onSave={handleSaveArrangement}
				onUndo={handleUndoAll}
				loading={loadingArrangement}
				data={data}
				// pass the separate readOnly expanded logic to the modal
				readOnlyExpandedIds={readOnlyExpandedIds}
				toggleReadOnlyExpanded={toggleReadOnlyExpanded}
			/>

			<View style={styles.mainContent}>
				{/* LEFT: Draggable Tree */}
				<View style={styles.treeOuterContainer}>
					<Col>
						<Button
							fullWidth
							onPress={handleReviewChanges}
							color="#1976d2"
							style={{ padding: 8, borderRadius: 8 }}
						>
							Review Changes
						</Button>
					</Col>

					<ScrollView
						ref={scrollViewRef}
						style={{ flex: 1 }}
						contentContainerStyle={{ flexGrow: 1 }}
						onScroll={handleScroll}
						scrollEventThrottle={16}
						scrollEnabled={scrollEnabled}
					>
						{data.map((item) => (
							<DragDropTreeItem
								key={item.id}
								item={item}
								level={0}
								selectedId={selectedId}
								setSelectedId={setSelectedId}
								expandedIds={expandedIds}
								toggleExpanded={toggleExpanded}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
								isDragging={draggingId}
								potentialParentId={potentialParentId}
								setPotentialParentId={setPotentialParentId}
								positions={positions}
								updatePositions={updatePositions}
								data={data}
								setData={setData}
								scrollViewRef={scrollViewRef}
							/>
						))}
					</ScrollView>
				</View>

				{/* RIGHT: Content View */}
				<View style={styles.contentView}>
					<ContentView selectedItem={findSelectedItem(data, selectedId)} />
				</View>
			</View>
		</Page>
	);
};

export default TreeView;

/** STYLES */
const styles = StyleSheet.create({
	pageContainer: {
		flex: 1,
		backgroundColor: "#fff",
	},
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
		backgroundColor: "#fff",
		minHeight: "100%",
	},
	treeOuterContainer: {
		width: 400,
		backgroundColor: "#fff",
		borderRightWidth: 1,
		borderRightColor: "#e0e0e0",
		minHeight: "100%",
	},
	contentView: {
		flex: 1,
		backgroundColor: "#fff",
	},
	contentContainer: {
		padding: 20,
	},
	noContentText: {
		fontSize: 16,
		color: "#666",
		textAlign: "center",
	},
	breadcrumbContainer: {
		marginBottom: 20,
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		paddingTop: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
	toolsHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	addButton: {
		backgroundColor: "#1976d2",
		padding: 8,
		borderRadius: 4,
		margin: 5,
	},
	addButtonText: {
		color: "white",
	},
});

// The read-only styles for the modal tree
const stylesReadOnly = StyleSheet.create({
	treeItemContainer: {
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
		borderBottomWidth: 1,
		borderBottomColor: "#f0f0f0",
	},
	expandButton: {
		padding: 4,
		marginRight: 8,
		width: 20,
		height: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	expandIcon: {
		fontSize: 18,
		fontWeight: "bold",
		color: "#666",
		lineHeight: 20,
	},
	itemText: {
		fontSize: 14,
		color: "#333",
	},
});

// The drag-drop styles for the main tree
const stylesDragDrop = StyleSheet.create({
	treeItemContainer: {
		width: "100%",
		backgroundColor: "#fff",
		borderRadius: 4,
		marginVertical: 1,
		borderWidth: 1,
		borderColor: "#e0e0e0",
	},
	itemInner: {
		flexDirection: "row",
		alignItems: "center",
		padding: 10,
	},
	expandButton: {
		width: 24,
		height: 24,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
	},
	expandIcon: {
		fontSize: 17,
		color: "#444",
	},
	itemText: {
		fontSize: 14,
		color: "#333",
		flex: 1,
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	dragHandle: {
		width: 40,
		height: 40,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#f0f0f0",
		borderRadius: 4,
		marginLeft: 8,
	},
	dragHandleDragging: {
		backgroundColor: "#bbdefb",
	},
	dragIconText: {
		fontSize: 24,
		color: "#666",
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	dropTarget: {
		backgroundColor: "#cce7f5",
		borderColor: "#80c4e7",
		borderWidth: 2,
		borderStyle: "dashed",
	},
	draggingItem: {
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
});
