// import React, { useState, useRef, useEffect } from "react";
// import {
// 	View,
// 	Text,
// 	TouchableOpacity,
// 	Animated,
// 	PanResponder,
// 	ScrollView,
// 	StyleSheet,
// } from "react-native";

// const initialData = [
// 	{
// 		id: "1",
// 		name: "Project A",
// 		children: [
// 			{ id: "1-1", name: "Task 1", children: [] },
// 			{ id: "1-2", name: "Task 2", children: [] },
// 		],
// 	},
// 	{
// 		id: "2",
// 		name: "Project B",
// 		children: [
// 			{
// 				id: "2-1",
// 				name: "Task 3",
// 				children: [{ id: "2-1-1", name: "Subtask 1", children: [] }],
// 			},
// 		],
// 	},
// ];

// const TreeItem = ({
// 	item,
// 	level,
// 	selectedId,
// 	setSelectedId,
// 	expandedIds,
// 	toggleExpanded,
// 	onDragStart,
// 	onDragEnd,
// 	isDragging,
// 	potentialParentId,
// 	setPotentialParentId,
// 	positions,
// 	updatePositions,
// }) => {
// 	const itemRef = useRef(null);
// 	const hasChildren = item.children && item.children.length > 0;
// 	const isExpanded = expandedIds.includes(item.id);
// 	const isSelected = selectedId === item.id;
// 	const isPotentialParent = potentialParentId === item.id;

// 	const pan = useRef(new Animated.ValueXY()).current;
// 	const scale = useRef(new Animated.Value(1)).current;

// 	// Update position in positions registry when mounted or expanded state changes
// 	useEffect(() => {
// 		if (itemRef.current) {
// 			itemRef.current.measure((x, y, width, height, pageX, pageY) => {
// 				updatePositions(item.id, {
// 					pageY,
// 					height,
// 					midPoint: pageY + height / 2,
// 				});
// 			});
// 		}
// 	}, [isExpanded]);

// 	// Find the closest item to current drag position
// 	const findClosestItem = (currentY) => {
// 		let closest = null;
// 		let minDistance = Infinity;

// 		Object.entries(positions).forEach(([id, pos]) => {
// 			if (id !== item.id) {
// 				// Don't consider the item being dragged
// 				const distance = Math.abs(currentY - pos.midPoint);
// 				if (distance < minDistance) {
// 					minDistance = distance;
// 					closest = id;
// 				}
// 			}
// 		});

// 		// Only return closest if within a reasonable distance (30px)
// 		return minDistance < 30 ? closest : null;
// 	};

// 	const panResponder = PanResponder.create({
// 		onStartShouldSetPanResponder: () => true,
// 		onMoveShouldSetPanResponder: () => true,

// 		onPanResponderGrant: () => {
// 			onDragStart(item.id);
// 			pan.setOffset({
// 				x: pan.x._value,
// 				y: pan.y._value,
// 			});
// 			Animated.spring(scale, {
// 				toValue: 1.03,
// 				useNativeDriver: true,
// 			}).start();
// 		},

// 		onPanResponderMove: (evt, gestureState) => {
// 			const { pageY } = evt.nativeEvent;
// 			const currentY = pageY;

// 			// Find closest item and set as potential parent
// 			const closestItemId = findClosestItem(currentY);
// 			setPotentialParentId(closestItemId);

// 			// Update drag position
// 			pan.setValue({
// 				x: gestureState.dx,
// 				y: gestureState.dy,
// 			});
// 		},

// 		onPanResponderRelease: () => {
// 			pan.flattenOffset();
// 			Animated.parallel([
// 				Animated.spring(pan, {
// 					toValue: { x: 0, y: 0 },
// 					useNativeDriver: true,
// 				}),
// 				Animated.spring(scale, {
// 					toValue: 1,
// 					useNativeDriver: true,
// 				}),
// 			]).start();

// 			onDragEnd(item.id, potentialParentId);
// 		},
// 	});

// 	const animatedStyle = {
// 		transform: [
// 			{ translateX: isDragging === item.id ? pan.x : 0 },
// 			{ translateY: isDragging === item.id ? pan.y : 0 },
// 			{ scale: isDragging === item.id ? scale : 1 },
// 		],
// 		zIndex: isDragging === item.id ? 999 : 1,
// 	};

// 	return (
// 		<View ref={itemRef}>
// 			<Animated.View
// 				{...panResponder.panHandlers}
// 				style={[
// 					styles.treeItem,
// 					{ marginLeft: level * 20 },
// 					isSelected && styles.selectedItem,
// 					isPotentialParent && styles.dropTarget,
// 					isDragging === item.id && styles.draggingItem,
// 					animatedStyle,
// 				]}
// 			>
// 				{hasChildren && (
// 					<TouchableOpacity
// 						style={styles.expandButton}
// 						onPress={() => toggleExpanded(item.id)}
// 					>
// 						<Text>{isExpanded ? "−" : "+"}</Text>
// 					</TouchableOpacity>
// 				)}
// 				<TouchableOpacity
// 					style={styles.itemContent}
// 					onPress={() => setSelectedId(item.id)}
// 				>
// 					<Text style={styles.itemText}>{item.name}</Text>
// 				</TouchableOpacity>
// 			</Animated.View>

// 			{isExpanded && hasChildren && (
// 				<View>
// 					{item.children.map((child) => (
// 						<TreeItem
// 							key={child.id}
// 							item={child}
// 							level={level + 1}
// 							selectedId={selectedId}
// 							setSelectedId={setSelectedId}
// 							expandedIds={expandedIds}
// 							toggleExpanded={toggleExpanded}
// 							onDragStart={onDragStart}
// 							onDragEnd={onDragEnd}
// 							isDragging={isDragging}
// 							potentialParentId={potentialParentId}
// 							setPotentialParentId={setPotentialParentId}
// 							positions={positions}
// 							updatePositions={updatePositions}
// 						/>
// 					))}
// 				</View>
// 			)}
// 		</View>
// 	);
// };

// const DraggableTree = () => {
// 	const [data, setData] = useState(initialData);
// 	const [selectedId, setSelectedId] = useState(null);
// 	const [expandedIds, setExpandedIds] = useState([]);
// 	const [draggingId, setDraggingId] = useState(null);
// 	const [potentialParentId, setPotentialParentId] = useState(null);
// 	const [positions, setPositions] = useState({});

// 	const toggleExpanded = (id) => {
// 		setExpandedIds((prev) =>
// 			prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
// 		);
// 	};

// 	const updatePositions = (id, position) => {
// 		setPositions((prev) => ({
// 			...prev,
// 			[id]: position,
// 		}));
// 	};

// 	const findItemById = (items, id) => {
// 		for (const item of items) {
// 			if (item.id === id) return item;
// 			if (item.children?.length) {
// 				const found = findItemById(item.children, id);
// 				if (found) return found;
// 			}
// 		}
// 		return null;
// 	};

// 	const removeItemById = (items, id) => {
// 		return items
// 			.map((item) => {
// 				if (item.id === id) return null;
// 				if (item.children?.length) {
// 					const newChildren = removeItemById(item.children, id).filter(Boolean);
// 					return { ...item, children: newChildren };
// 				}
// 				return item;
// 			})
// 			.filter(Boolean);
// 	};

// 	const handleDragStart = (id) => {
// 		setDraggingId(id);
// 	};

// 	const handleDragEnd = (draggedId, dropTargetId) => {
// 		if (!dropTargetId || draggedId === dropTargetId) {
// 			setDraggingId(null);
// 			setPotentialParentId(null);
// 			return;
// 		}

// 		const draggedItem = findItemById(data, draggedId);
// 		if (!draggedItem) return;

// 		let newData = removeItemById(data, draggedId);

// 		const addToTarget = (items) => {
// 			return items.map((item) => {
// 				if (item.id === dropTargetId) {
// 					return {
// 						...item,
// 						children: [...(item.children || []), draggedItem],
// 					};
// 				}
// 				if (item.children?.length) {
// 					return {
// 						...item,
// 						children: addToTarget(item.children),
// 					};
// 				}
// 				return item;
// 			});
// 		};

// 		newData = addToTarget(newData);
// 		setData(newData);

// 		// Expand the target node
// 		if (!expandedIds.includes(dropTargetId)) {
// 			setExpandedIds((prev) => [...prev, dropTargetId]);
// 		}

// 		setDraggingId(null);
// 		setPotentialParentId(null);
// 	};

// 	return (
// 		<ScrollView style={styles.container}>
// 			{data.map((item) => (
// 				<TreeItem
// 					key={item.id}
// 					item={item}
// 					level={0}
// 					selectedId={selectedId}
// 					setSelectedId={setSelectedId}
// 					expandedIds={expandedIds}
// 					toggleExpanded={toggleExpanded}
// 					onDragStart={handleDragStart}
// 					onDragEnd={handleDragEnd}
// 					isDragging={draggingId}
// 					potentialParentId={potentialParentId}
// 					setPotentialParentId={setPotentialParentId}
// 					positions={positions}
// 					updatePositions={updatePositions}
// 				/>
// 			))}
// 		</ScrollView>
// 	);
// };

// const styles = StyleSheet.create({
// 	container: {
// 		flex: 1,
// 		backgroundColor: "#fff",
// 		padding: 16,
// 	},
// 	treeItem: {
// 		flexDirection: "row",
// 		alignItems: "center",
// 		padding: 12,
// 		backgroundColor: "#fff",
// 		borderRadius: 4,
// 		marginVertical: 2,
// 		borderWidth: 1,
// 		borderColor: "#e0e0e0",
// 	},
// 	selectedItem: {
// 		backgroundColor: "#e3f2fd",
// 	},
// 	dropTarget: {
// 		backgroundColor: "#e8f5e9",
// 		borderColor: "#4caf50",
// 		borderWidth: 2,
// 		borderStyle: "dashed",
// 	},
// 	draggingItem: {
// 		shadowColor: "#000",
// 		shadowOffset: {
// 			width: 0,
// 			height: 2,
// 		},
// 		shadowOpacity: 0.25,
// 		shadowRadius: 3.84,
// 		elevation: 5,
// 	},
// 	expandButton: {
// 		width: 24,
// 		height: 24,
// 		alignItems: "center",
// 		justifyContent: "center",
// 		marginRight: 8,
// 	},
// 	itemContent: {
// 		flex: 1,
// 	},
// 	itemText: {
// 		fontSize: 14,
// 		color: "#333",
// 	},
// });

// export default DraggableTree;

import React, { useState, useRef, useEffect } from "react";
import {
	View,
	Text,
	Animated,
	PanResponder,
	ScrollView,
	StyleSheet,
	Pressable,
} from "react-native";

const initialData = [
	{
		id: "1",
		name: "Project A",
		children: [
			{ id: "1-1", name: "Task 1", children: [] },
			{ id: "1-2", name: "Task 2", children: [] },
		],
	},
	{
		id: "2",
		name: "Project B",
		children: [
			{
				id: "2-1",
				name: "Task 3",
				children: [{ id: "2-1-1", name: "Subtask 1", children: [] }],
			},
		],
	},
];

/**
 * TreeItem component
 */
const TreeItem = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
	onDragStart,
	onDragEnd,
	isDragging,
	potentialParentId,
	setPotentialParentId,
	positions,
	updatePositions,
}) => {
	// Whether we're currently allowing dragging (triggered on long press)
	const [dragEnabled, setDragEnabled] = useState(false);

	// Reference to the Pressable container
	const itemRef = useRef(null);

	// Animated values for X/Y movement
	const pan = useRef(new Animated.ValueXY()).current;

	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = expandedIds.includes(item.id);
	const isSelected = selectedId === item.id;
	const isPotentialParent = potentialParentId === item.id;

	/**
	 * Measure the Pressable container in global coordinates after layout.
	 * We'll store the midPoint of the item for later drag computations.
	 */
	const onLayoutItem = () => {
		if (itemRef.current) {
			itemRef.current.measureInWindow((winX, winY, width, height) => {
				if (typeof winY !== "number") return; // guard in case measure fails

				updatePositions(item.id, {
					pageY: winY,
					height,
					midPoint: winY + height / 2,
				});
			});
		}
	};

	/**
	 * Finds the closest item to the current drag Y position
	 */
	const findClosestItem = (currentY) => {
		let closest = null;
		let minDistance = Infinity;

		Object.entries(positions).forEach(([id, pos]) => {
			// Skip the item being dragged
			if (id === item.id) return;
			const distance = Math.abs(currentY - pos.midPoint);
			if (distance < minDistance) {
				minDistance = distance;
				closest = id;
			}
		});
		// Only return the closest if within a certain threshold
		return minDistance < 40 ? closest : null;
	};

	/**
	 * PanResponder logic
	 */
	const panResponder = PanResponder.create({
		onStartShouldSetPanResponder: () => dragEnabled,
		onMoveShouldSetPanResponder: () => dragEnabled,

		onPanResponderGrant: () => {
			if (!dragEnabled) return;
			onDragStart(item.id);

			// Reset offset so dragging starts smoothly
			pan.setOffset({
				x: pan.x._value,
				y: pan.y._value,
			});
		},

		onPanResponderMove: (evt, gestureState) => {
			if (!dragEnabled) return;

			// Current finger position in screen coords
			const { pageY } = evt.nativeEvent;

			// Identify potential new parent
			const closestItemId = findClosestItem(pageY);
			setPotentialParentId(closestItemId);

			// Update animated values
			pan.setValue({
				x: gestureState.dx,
				y: gestureState.dy,
			});
		},

		onPanResponderRelease: () => {
			if (!dragEnabled) return;

			// Flatten offset to avoid jump
			pan.flattenOffset();

			// Immediately reset position (no spring => immediate)
			pan.setValue({ x: 0, y: 0 });

			// Trigger final reorder
			onDragEnd(item.id, potentialParentId);

			// Disable drag for next time
			setDragEnabled(false);
		},

		onPanResponderTerminate: () => {
			// If another responder took over, reset drag
			pan.setValue({ x: 0, y: 0 });
			setDragEnabled(false);
		},
	});

	/**
	 * Animated style for the item while dragging
	 */
	const animatedStyle = {
		transform: [
			{ translateX: isDragging === item.id ? pan.x : 0 },
			{ translateY: isDragging === item.id ? pan.y : 0 },
		],
		zIndex: isDragging === item.id ? 999 : 1,
	};

	/**
	 * Handle a quick tap (select item)
	 */
	const handlePress = () => {
		setSelectedId(item.id);
	};

	/**
	 * Handle a long press => Enable dragging
	 */
	const handleLongPress = () => {
		setDragEnabled(true);
	};

	return (
		<View
			ref={itemRef}
			onLayout={onLayoutItem} // measure on layout
			style={{ marginLeft: level * 20 }}
		>
			<Pressable
				onPress={handlePress}
				onLongPress={handleLongPress}
				delayLongPress={300} // hold for 300ms to start drag
				style={[
					styles.treeItemContainer,
					isSelected && styles.selectedItem,
					isPotentialParent && styles.dropTarget,
					isDragging === item.id && styles.draggingItem,
				]}
			>
				{/* The actual draggable content is the Animated.View */}
				<Animated.View {...panResponder.panHandlers} style={animatedStyle}>
					<View style={styles.itemInner}>
						{/* Expand/collapse button for items with children */}
						{hasChildren && (
							<Pressable
								onPress={() => toggleExpanded(item.id)}
								style={styles.expandButton}
							>
								<Text>{isExpanded ? "−" : "+"}</Text>
							</Pressable>
						)}
						<Text style={styles.itemText}>{item.name}</Text>
					</View>
				</Animated.View>
			</Pressable>

			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<TreeItem
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
							onDragStart={onDragStart}
							onDragEnd={onDragEnd}
							isDragging={isDragging}
							potentialParentId={potentialParentId}
							setPotentialParentId={setPotentialParentId}
							positions={positions}
							updatePositions={updatePositions}
						/>
					))}
				</View>
			)}
		</View>
	);
};

/**
 * The main DraggableTree component
 */
const DraggableTree = () => {
	const [data, setData] = useState(initialData);

	// Visual states
	const [selectedId, setSelectedId] = useState(null);
	const [expandedIds, setExpandedIds] = useState([]);

	// Drag/drop states
	const [draggingId, setDraggingId] = useState(null);
	const [potentialParentId, setPotentialParentId] = useState(null);

	// Positions cache: { [id]: { pageY, height, midPoint } }
	const [positions, setPositions] = useState({});

	/**
	 * Expand or collapse by id
	 */
	const toggleExpanded = (id) => {
		setExpandedIds((prev) =>
			prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
		);
	};

	/**
	 * Register or update an item's position
	 */
	const updatePositions = (id, position) => {
		setPositions((prev) => ({
			...prev,
			[id]: position,
		}));
	};

	/**
	 * Find an item by id
	 */
	const findItemById = (items, id) => {
		for (const item of items) {
			if (item.id === id) return item;
			if (item.children?.length) {
				const found = findItemById(item.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	/**
	 * Remove an item from the tree
	 */
	const removeItemById = (items, id) => {
		return items
			.map((item) => {
				if (item.id === id) return null;
				if (item.children?.length) {
					return {
						...item,
						children: removeItemById(item.children, id).filter(Boolean),
					};
				}
				return item;
			})
			.filter(Boolean);
	};

	/**
	 * Called when dragging starts
	 */
	const handleDragStart = (id) => {
		setDraggingId(id);
	};

	/**
	 * Called when dragging ends
	 */
	const handleDragEnd = (draggedId, dropTargetId) => {
		if (draggedId === dropTargetId) {
			setDraggingId(null);
			setPotentialParentId(null);
			return;
		}

		// If there's no valid parent, become a top-level item
		if (!dropTargetId) {
			const draggedItem = findItemById(data, draggedId);
			if (!draggedItem) {
				setDraggingId(null);
				setPotentialParentId(null);
				return;
			}
			let newData = removeItemById(data, draggedId);
			newData.push(draggedItem);
			setData(newData);

			setDraggingId(null);
			setPotentialParentId(null);
			return;
		}

		// If dropping onto a valid parent
		const draggedItem = findItemById(data, draggedId);
		if (!draggedItem) {
			setDraggingId(null);
			setPotentialParentId(null);
			return;
		}
		let newData = removeItemById(data, draggedId);

		// Place item under target's children
		const addToTarget = (items) => {
			return items.map((node) => {
				if (node.id === dropTargetId) {
					return {
						...node,
						children: [...(node.children || []), draggedItem],
					};
				}
				if (node.children?.length) {
					return {
						...node,
						children: addToTarget(node.children),
					};
				}
				return node;
			});
		};

		newData = addToTarget(newData);
		setData(newData);

		// Expand the drop target so newly dropped item is visible
		if (!expandedIds.includes(dropTargetId)) {
			setExpandedIds((prev) => [...prev, dropTargetId]);
		}

		// Reset drag
		setDraggingId(null);
		setPotentialParentId(null);
	};

	return (
		<ScrollView style={styles.container}>
			{data.map((item) => (
				<TreeItem
					key={item.id}
					item={item}
					level={0}
					selectedId={selectedId}
					setSelectedId={setSelectedId}
					expandedIds={expandedIds}
					toggleExpanded={toggleExpanded}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					isDragging={draggingId}
					potentialParentId={potentialParentId}
					setPotentialParentId={setPotentialParentId}
					positions={positions}
					updatePositions={updatePositions}
				/>
			))}
		</ScrollView>
	);
};

/**
 * Styles
 */
const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		padding: 16,
	},
	treeItemContainer: {
		backgroundColor: "#fff",
		borderRadius: 4,
		marginVertical: 2,
		borderWidth: 1,
		borderColor: "#e0e0e0",
	},
	itemInner: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	dropTarget: {
		backgroundColor: "#e8f5e9",
		borderColor: "#4caf50",
		borderWidth: 2,
		borderStyle: "dashed",
	},
	draggingItem: {
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	expandButton: {
		width: 24,
		height: 24,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
	},
	itemText: {
		fontSize: 14,
		color: "#333",
	},
});

export default DraggableTree;
