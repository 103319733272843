// import React, { useEffect, useState, useRef } from "react";
// import {
// 	View,
// 	Text,
// 	StyleSheet,
// 	ScrollView,
// 	Pressable,
// 	TouchableOpacity,
// } from "react-native"; // For web, Expo will compile to react-native-web
// import styled from "styled-components/native";
// import { Divider } from "react-native-elements";
// import { useStoreActions, useStoreState } from "easy-peasy";

// // ----- Imported from your original code -----
// import Form from "../containers/Form";
// import Row from "../presentational/Row";
// import Col from "../presentational/Col";
// import Input from "../presentational/Input";
// import Switch from "../presentational/Switch";
// import Button from "../presentational/Button";
// import Gap from "../presentational/Gap";
// import Modal from "../functional/Modal";
// import ToolSelectionModal from "../containers/Modal/TestSelectionModal";
// import WorkScopeToolList from "../functional/List/WorkScopeToolList";
// import Page from "../containers/Page/Page";
// import { Breadcrumb } from "../presentational/Breadcrumb";
// import { height } from "../../utilities/general";
// // --------------------------------------------

// /**
//  * A styled label from your original code
//  */
// const Label = styled(Text)`
// 	color: #00205b;
// `;

// /**
//  * TreeItem component
//  * Shows name, expand button, and (in edit mode) up/down/indent/outdent arrows.
//  */
// const TreeItem = ({
// 	item,
// 	level,
// 	selectedId,
// 	setSelectedId,
// 	expandedIds,
// 	toggleExpanded,
// 	isEditMode,
// 	moveItemUp,
// 	moveItemDown,
// 	indentItem,
// 	outdentItem,
// }) => {
// 	const hasChildren = !!(item.children && item.children.length > 0);
// 	const isExpanded = expandedIds.has(item.id);
// 	const isSelected = selectedId === item.id;

// 	return (
// 		<View style={{ marginLeft: level * 20 }}>
// 			<Pressable
// 				onPress={() => setSelectedId(item.id)}
// 				style={[
// 					stylesDragDrop.treeItemContainer,
// 					isSelected && stylesDragDrop.selectedItem,
// 				]}
// 			>
// 				<View style={stylesDragDrop.itemInner}>
// 					{/* Expand/collapse toggle if there are children */}
// 					{hasChildren && (
// 						<TouchableOpacity
// 							style={stylesDragDrop.expandButton}
// 							onPress={() => toggleExpanded(item.id)}
// 						>
// 							<Text style={stylesDragDrop.expandIcon}>
// 								{isExpanded ? "-" : "+"}
// 							</Text>
// 						</TouchableOpacity>
// 					)}

// 					{/* Item name */}
// 					<Text
// 						style={[
// 							stylesDragDrop.itemText,
// 							isSelected && stylesDragDrop.selectedText,
// 						]}
// 					>
// 						{item.name}
// 					</Text>

// 					{/* Only show arrows if we are in edit mode */}
// 					{isEditMode && (
// 						<View style={{ flexDirection: "row" }}>
// 							<TouchableOpacity
// 								style={stylesDragDrop.arrowButton}
// 								onPress={() => moveItemUp(item.id)}
// 							>
// 								<Text style={stylesDragDrop.arrowText}>↑</Text>
// 							</TouchableOpacity>
// 							<TouchableOpacity
// 								style={stylesDragDrop.arrowButton}
// 								onPress={() => moveItemDown(item.id)}
// 							>
// 								<Text style={stylesDragDrop.arrowText}>↓</Text>
// 							</TouchableOpacity>
// 							<TouchableOpacity
// 								style={stylesDragDrop.arrowButton}
// 								onPress={() => indentItem(item.id)}
// 							>
// 								<Text style={stylesDragDrop.arrowText}>→</Text>
// 							</TouchableOpacity>
// 							<TouchableOpacity
// 								style={stylesDragDrop.arrowButton}
// 								onPress={() => outdentItem(item.id)}
// 							>
// 								<Text style={stylesDragDrop.arrowText}>←</Text>
// 							</TouchableOpacity>
// 						</View>
// 					)}
// 				</View>
// 			</Pressable>

// 			{/* Render children if expanded */}
// 			{isExpanded && hasChildren && (
// 				<View>
// 					{item.children.map((child) => (
// 						<TreeItem
// 							key={child.id}
// 							item={child}
// 							level={level + 1}
// 							selectedId={selectedId}
// 							setSelectedId={setSelectedId}
// 							expandedIds={expandedIds}
// 							toggleExpanded={toggleExpanded}
// 							isEditMode={isEditMode}
// 							moveItemUp={moveItemUp}
// 							moveItemDown={moveItemDown}
// 							indentItem={indentItem}
// 							outdentItem={outdentItem}
// 						/>
// 					))}
// 				</View>
// 			)}
// 		</View>
// 	);
// };

// /**
//  * ContentView: (Mostly unchanged from your code).
//  * Shows details for the currently selected item.
//  */
// const ContentView = ({ selectedItem }) => {
// 	const [selectedWS, setSelectedWS] = useState({
// 		workscopename: "",
// 		enginefamilyid: "",
// 		enginevariantid: "",
// 		workpackageid: "",
// 		technicalmanualreference: "",
// 		quantity: "",
// 		wiptool: "",
// 		usewith: "",
// 	});

// 	const {
// 		workscope: { input, toolInput, list, toolList },
// 		workpackage: { list: wpList },
// 	} = useStoreState((state) => state);

// 	const {
// 		getWorkScopeToolsThunk,
// 		setWorkScopeInputAction,
// 		setWorkScopeToolInputAction,
// 		upsertWorkScopeToolThunk,
// 		upsertWorkScopeThunk,
// 		deleteWorkScopeThunk,
// 	} = useStoreActions((actions) => actions);

// 	const [toolModalVisible, setToolSelectionModalVisible] = useState(false);

// 	useEffect(() => {
// 		if (!selectedItem) return;
// 		if (input.id !== selectedItem.id) {
// 			const selectedData = list.find((ws) => ws.id === selectedItem.id);
// 			if (selectedData) {
// 				setWorkScopeInputAction(selectedData);
// 				getWorkScopeToolsThunk({ id: selectedItem.id });
// 			}
// 		}
// 	}, [
// 		selectedItem,
// 		list,
// 		input.id,
// 		setWorkScopeInputAction,
// 		getWorkScopeToolsThunk,
// 	]);

// 	// Breadcrumb
// 	const [breadcrumbItems, setBreadcrumbItems] = useState([]);
// 	useEffect(() => {
// 		const items = [
// 			{
// 				id: "engineFamily",
// 				name: "LEAP",
// 			},
// 			{
// 				id: "engineVariant",
// 				name: "1A",
// 			},
// 		];

// 		if (input.wsparentid) {
// 			const parentWorkscope = list.find((ws) => ws.id === input.wsparentid);
// 			if (parentWorkscope) {
// 				items.push({
// 					id: parentWorkscope.id,
// 					name: parentWorkscope.workscopename,
// 				});
// 			}
// 			if (input.workscopename) {
// 				items.push({
// 					id: input.id,
// 					name: input.workscopename,
// 				});
// 			}
// 		}
// 		setBreadcrumbItems(items);
// 	}, [
// 		input.enginefamilyid,
// 		input.enginevariantid,
// 		input.wsparentid,
// 		list,
// 		toolList,
// 	]);

// 	return (
// 		<View style={styles.contentContainer}>
// 			<Modal
// 				title="Add Work Scope Tool"
// 				isVisible={toolModalVisible}
// 				onClose={() => setToolSelectionModalVisible(false)}
// 			>
// 				<Row>
// 					<Col>
// 						<Input
// 							label="Part Number"
// 							onChangeText={(partnumber) =>
// 								setWorkScopeToolInputAction({ partnumber })
// 							}
// 							value={toolInput.partnumber}
// 							required
// 						/>
// 					</Col>
// 				</Row>
// 				<Row>
// 					<Col>
// 						<Input
// 							label="Tool Nomenclature"
// 							onChangeText={(toolnomenclature) =>
// 								setWorkScopeToolInputAction({ toolnomenclature })
// 							}
// 							value={toolInput.toolnomenclature}
// 							required
// 						/>
// 					</Col>
// 				</Row>
// 				<Row>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Qualified Revision"
// 							onChangeText={(qualifiedrevision) =>
// 								setWorkScopeToolInputAction({ qualifiedrevision })
// 							}
// 							value={toolInput.qualifiedrevision}
// 							required
// 						/>
// 					</Col>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Dimensions"
// 							onChangeText={(dimensions) =>
// 								setWorkScopeToolInputAction({ dimensions })
// 							}
// 							value={toolInput.dimensions}
// 							required
// 						/>
// 					</Col>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Weight"
// 							onChangeText={(weight) => setWorkScopeToolInputAction({ weight })}
// 							value={toolInput.weight}
// 							required
// 						/>
// 					</Col>
// 				</Row>

// 				<Row>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Load Test"
// 							onChangeText={(loadtest) =>
// 								setWorkScopeToolInputAction({ loadtest })
// 							}
// 							value={toolInput.loadtest}
// 							required
// 						/>
// 					</Col>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Tool Code"
// 							onChangeText={(toolcode) =>
// 								setWorkScopeToolInputAction({ toolcode })
// 							}
// 							value={toolInput.toolcode}
// 							required
// 						/>
// 					</Col>
// 					<Col xs={12} md={4}>
// 						<Input
// 							label="Calibration"
// 							onChangeText={(calibration) =>
// 								setWorkScopeToolInputAction({ calibration })
// 							}
// 							value={toolInput.calibration}
// 							required
// 						/>
// 					</Col>
// 				</Row>

// 				<View style={styles.modalFooter}>
// 					<TouchableOpacity style={[styles.button, styles.cancelButton]}>
// 						<Text style={styles.buttonText}>Cancel</Text>
// 					</TouchableOpacity>
// 					<TouchableOpacity
// 						style={[styles.button, styles.saveButton]}
// 						onPress={() => {
// 							upsertWorkScopeToolThunk();
// 						}}
// 					>
// 						<Text style={[styles.buttonText, styles.saveButtonText]}>
// 							Save Selection
// 						</Text>
// 					</TouchableOpacity>
// 				</View>
// 			</Modal>

// 			<View style={styles.breadcrumbContainer}>
// 				<Divider />
// 			</View>

// 			{selectedItem ? (
// 				<Form>
// 					<Row>
// 						<Col xs={12} md={8}>
// 							<Input
// 								label="Work Scope Name"
// 								onChangeText={(workscopename) =>
// 									setWorkScopeInputAction({ workscopename })
// 								}
// 								value={input.workscopename}
// 								required
// 							/>
// 						</Col>
// 						<Col xs={12} md={2}>
// 							<Button
// 								style={{ borderRadius: 8 }}
// 								onPress={() => {
// 									deleteWorkScopeThunk();
// 								}}
// 								color="#ab2328"
// 								fullWidth
// 							>
// 								Delete
// 							</Button>
// 						</Col>
// 						<Col xs={12} md={2}>
// 							<Button
// 								style={{ borderRadius: 8 }}
// 								onPress={() => {
// 									upsertWorkScopeThunk();
// 								}}
// 								color="#1976d2"
// 								fullWidth
// 							>
// 								Save
// 							</Button>
// 						</Col>
// 					</Row>

// 					<Row>
// 						<Col>
// 							<Input
// 								label="Work Scope Description"
// 								onChangeText={(workscopedescription) =>
// 									setWorkScopeInputAction({ workscopedescription })
// 								}
// 								value={input.workscopedescription}
// 								required
// 							/>
// 						</Col>
// 					</Row>

// 					<Row>
// 						<Col xs={12} md={5}>
// 							<Input
// 								label="Work Scope Parent"
// 								onChangeText={(wsparentid) =>
// 									setWorkScopeInputAction({ wsparentid })
// 								}
// 								value={input.wsparentid}
// 								required
// 							/>
// 						</Col>
// 						<Col xs={12} md={7}>
// 							<Input
// 								label="Work Scope Order"
// 								onChangeText={(ordernum) =>
// 									setWorkScopeInputAction({ ordernum })
// 								}
// 								value={input.ordernum}
// 								required
// 							/>
// 						</Col>
// 					</Row>

// 					<Row>
// 						<Col xs={12} md={3}>
// 							<Input
// 								label="Work Package"
// 								value={
// 									input.workpackageid
// 										? wpList?.find((w) => w.id === input.workpackageid)
// 												?.description
// 										: "N/A"
// 								}
// 								onSelect={(item) => {
// 									setWorkScopeInputAction({
// 										workpackageid: item.id,
// 										wpdescription: item.description,
// 									});
// 								}}
// 								required
// 								dropdown
// 								dropdownChoices={wpList}
// 								selectedItem={(item) => item.description}
// 								rowTextForSelection={(item) => item.description}
// 							/>
// 						</Col>
// 						<Col xs={12} md={9}>
// 							<Input
// 								editable={false}
// 								label="Work Package Description"
// 								onChangeText={(wpdescription) =>
// 									setWorkScopeInputAction({ wpdescription })
// 								}
// 								value={input.wpdescription || "N/A"}
// 								required
// 							/>
// 						</Col>
// 					</Row>

// 					<Row>
// 						<Col>
// 							<Label>Notes</Label>
// 							<Input
// 								multiline
// 								numberOfLines={4}
// 								onChangeText={(notes) => setWorkScopeInputAction({ notes })}
// 								value={input.notes}
// 								required
// 							/>
// 						</Col>
// 					</Row>

// 					<Row>
// 						<Col>
// 							<View style={styles.toolsHeader}>
// 								<Label>Tools</Label>
// 								<TouchableOpacity
// 									style={styles.addButton}
// 									onPress={() => setToolSelectionModalVisible(true)}
// 								>
// 									<Text style={styles.addButtonText}>Add</Text>
// 								</TouchableOpacity>
// 							</View>
// 							<WorkScopeToolList />
// 						</Col>
// 					</Row>
// 				</Form>
// 			) : (
// 				<Text style={styles.noContentText}>Select an item to view details</Text>
// 			)}
// 		</View>
// 	);
// };

// /**
//  * Main component that holds the tree on the left (with reorder logic)
//  * and the details form on the right.
//  */
// const TreeViewArrows = () => {
// 	const scrollViewRef = useRef(null);
// 	const [data, setData] = useState([]);

// 	const [selectedId, setSelectedId] = useState(null);
// 	const [expandedIds, setExpandedIds] = useState(new Set());
// 	const [isEditMode, setIsEditMode] = useState(false);

// 	// easy-peasy store references
// 	const {
// 		workscope: { selectedData },
// 	} = useStoreState((state) => state);
// 	const { getWorkScopeThunk, getWorkPackageThunk } = useStoreActions(
// 		(actions) => actions
// 	);

// 	useEffect(() => {
// 		getWorkScopeThunk({
// 			enginefamilyid: 1,
// 			enginevariantid: 1,
// 		});
// 		getWorkPackageThunk({});
// 	}, [getWorkScopeThunk, getWorkPackageThunk]);

// 	// Whenever `selectedData` changes (from store), sync to local `data`
// 	useEffect(() => {
// 		if (Array.isArray(selectedData)) {
// 			setData(selectedData);
// 		}
// 	}, [selectedData]);

// 	// Expand/collapse logic
// 	const toggleExpanded = (id) => {
// 		setExpandedIds((prev) => {
// 			const next = new Set(prev);
// 			if (next.has(id)) {
// 				next.delete(id);
// 			} else {
// 				next.add(id);
// 			}
// 			return next;
// 		});
// 	};

// 	// Find selected item in local data (recursive)
// 	const findSelectedItem = (items, id) => {
// 		for (const node of items) {
// 			if (node.id === id) return node;
// 			if (node.children) {
// 				const found = findSelectedItem(node.children, id);
// 				if (found) return found;
// 			}
// 		}
// 		return null;
// 	};

// 	/**
// 	 * Helper functions for reordering
// 	 */
// 	const clone = (obj) => JSON.parse(JSON.stringify(obj));

// 	// Find the parent of an item with given id
// 	const findParent = (items, targetId, parent = null) => {
// 		for (const node of items) {
// 			if (node.id === targetId) {
// 				return parent;
// 			}
// 			if (node.children) {
// 				const possible = findParent(node.children, targetId, node);
// 				if (possible) return possible;
// 			}
// 		}
// 		return null;
// 	};

// 	// Move item up among siblings
// 	const moveItemUp = (itemId) => {
// 		setData((prevData) => {
// 			const newData = clone(prevData);
// 			const parent = findParent(newData, itemId);
// 			const siblings = parent ? parent.children : newData;
// 			const idx = siblings.findIndex((s) => s.id === itemId);

// 			if (idx > 0) {
// 				// swap with previous sibling
// 				[siblings[idx - 1], siblings[idx]] = [siblings[idx], siblings[idx - 1]];
// 			}
// 			return newData;
// 		});
// 	};

// 	// Move item down among siblings
// 	const moveItemDown = (itemId) => {
// 		setData((prevData) => {
// 			const newData = clone(prevData);
// 			const parent = findParent(newData, itemId);
// 			const siblings = parent ? parent.children : newData;
// 			const idx = siblings.findIndex((s) => s.id === itemId);

// 			if (idx < siblings.length - 1) {
// 				// swap with next sibling
// 				[siblings[idx], siblings[idx + 1]] = [siblings[idx + 1], siblings[idx]];
// 			}
// 			return newData;
// 		});
// 	};

// 	/**
// 	 * Indent (→):
// 	 * - Make the item a child of its immediately preceding sibling (if any).
// 	 */
// 	const indentItem = (itemId) => {
// 		setData((prevData) => {
// 			const newData = clone(prevData);

// 			const parent = findParent(newData, itemId);
// 			const siblings = parent ? parent.children : newData;
// 			const idx = siblings.findIndex((s) => s.id === itemId);

// 			// We can only indent if there's a sibling above
// 			if (idx > 0) {
// 				const itemToIndent = siblings[idx];
// 				const newParent = siblings[idx - 1]; // the preceding sibling

// 				// remove from current siblings
// 				siblings.splice(idx, 1);

// 				// ensure new parent has children
// 				newParent.children = newParent.children || [];
// 				// push item as last child of that preceding sibling
// 				newParent.children.push(itemToIndent);

// 				// automatically expand the new parent so we can see it
// 				if (!expandedIds.has(newParent.id)) {
// 					setExpandedIds((prev) => {
// 						const next = new Set(prev);
// 						next.add(newParent.id);
// 						return next;
// 					});
// 				}
// 			}

// 			return newData;
// 		});
// 	};

// 	/**
// 	 * Outdent (←):
// 	 * - Move the item to become a sibling of its current parent.
// 	 * - So the item’s grandparent is the new parent (or top-level if no grandparent).
// 	 */
// 	const outdentItem = (itemId) => {
// 		setData((prevData) => {
// 			const newData = clone(prevData);

// 			const parent = findParent(newData, itemId);
// 			// If there's no parent, it's already top-level => cannot outdent further
// 			if (!parent) {
// 				return newData;
// 			}
// 			const grandParent = findParent(newData, parent.id);

// 			// remove the item from the parent's children
// 			const parentSiblings = parent.children;
// 			const idx = parentSiblings.findIndex((s) => s.id === itemId);
// 			if (idx === -1) return newData; // shouldn't happen but just in case
// 			const itemToOutdent = parentSiblings[idx];
// 			parentSiblings.splice(idx, 1);

// 			// If we have a grandParent, we attach itemToOutdent to grandParent’s children
// 			if (grandParent) {
// 				// find the parent's position in grandParent.children
// 				const gpSiblings = grandParent.children;
// 				const parentIndex = gpSiblings.findIndex((s) => s.id === parent.id);

// 				// we insert item right after the parent
// 				gpSiblings.splice(parentIndex + 1, 0, itemToOutdent);
// 			} else {
// 				// No grandparent => move to top-level
// 				newData.push(itemToOutdent);
// 			}

// 			return newData;
// 		});
// 	};

// 	return (
// 		<Page style={styles.container}>
// 			{/* Top bar with the Edit toggle */}
// 			<View style={styles.topBar}>
// 				<Text style={styles.title}>Tree View</Text>
// 				<TouchableOpacity
// 					style={[styles.editButton, isEditMode && styles.editButtonActive]}
// 					onPress={() => setIsEditMode(!isEditMode)}
// 				>
// 					<Text style={styles.editButtonText}>
// 						{isEditMode ? "Done" : "Edit"}
// 					</Text>
// 				</TouchableOpacity>
// 			</View>

// 			<View style={styles.mainContent}>
// 				{/* LEFT: Tree */}
// 				<View style={styles.treeOuterContainer}>
// 					<ScrollView
// 						style={{ flex: 1 }}
// 						ref={scrollViewRef}
// 						contentContainerStyle={{ flexGrow: 1 }}
// 					>
// 						{data.map((item) => (
// 							<TreeItem
// 								key={item.id}
// 								item={item}
// 								level={0}
// 								selectedId={selectedId}
// 								setSelectedId={setSelectedId}
// 								expandedIds={expandedIds}
// 								toggleExpanded={toggleExpanded}
// 								isEditMode={isEditMode}
// 								moveItemUp={moveItemUp}
// 								moveItemDown={moveItemDown}
// 								indentItem={indentItem}
// 								outdentItem={outdentItem}
// 							/>
// 						))}
// 					</ScrollView>
// 				</View>

// 				{/* RIGHT: ContentView (details form) */}
// 				<View style={styles.contentView}>
// 					<ContentView selectedItem={findSelectedItem(data, selectedId)} />
// 				</View>
// 			</View>
// 		</Page>
// 	);
// };

// export default TreeViewArrows;

// /**
//  * Styles
//  */
// const styles = StyleSheet.create({
// 	container: {
// 		flex: 1,
// 		backgroundColor: "#fff",
// 	},
// 	topBar: {
// 		height: 60,
// 		paddingHorizontal: 20,
// 		backgroundColor: "#f5f5f5",
// 		flexDirection: "row",
// 		alignItems: "center",
// 		justifyContent: "space-between",
// 		borderBottomWidth: 1,
// 		borderBottomColor: "#ccc",
// 	},
// 	title: {
// 		fontSize: 20,
// 		fontWeight: "bold",
// 	},
// 	editButton: {
// 		paddingHorizontal: 12,
// 		paddingVertical: 6,
// 		backgroundColor: "#1976d2",
// 		borderRadius: 4,
// 	},
// 	editButtonActive: {
// 		backgroundColor: "#0d47a1",
// 	},
// 	editButtonText: {
// 		color: "#fff",
// 		fontSize: 14,
// 	},
// 	mainContent: {
// 		flex: 1,
// 		flexDirection: "row",
// 		backgroundColor: "#fff",
// 		minHeight: "100%",
// 	},
// 	treeOuterContainer: {
// 		width: 400,
// 		backgroundColor: "#fff",
// 		borderRightWidth: 1,
// 		borderRightColor: "#e0e0e0",
// 	},
// 	contentView: {
// 		flex: 1,
// 		backgroundColor: "#fff",
// 	},
// 	contentContainer: {
// 		padding: 20,
// 	},
// 	noContentText: {
// 		fontSize: 16,
// 		color: "#666",
// 		textAlign: "center",
// 	},
// 	breadcrumbContainer: {
// 		marginBottom: 20,
// 	},
// 	modalFooter: {
// 		flexDirection: "row",
// 		justifyContent: "flex-end",
// 		paddingTop: 10,
// 		borderTopWidth: 1,
// 		borderTopColor: "#eee",
// 	},
// 	button: {
// 		paddingVertical: 8,
// 		paddingHorizontal: 16,
// 		borderRadius: 4,
// 		marginLeft: 12,
// 	},
// 	cancelButton: {
// 		backgroundColor: "#f5f5f5",
// 	},
// 	saveButton: {
// 		backgroundColor: "#1976d2",
// 	},
// 	buttonText: {
// 		fontSize: 14,
// 		color: "#333",
// 	},
// 	saveButtonText: {
// 		color: "white",
// 	},
// 	toolsHeader: {
// 		flexDirection: "row",
// 		justifyContent: "space-between",
// 		alignItems: "center",
// 	},
// 	addButton: {
// 		backgroundColor: "#1976d2",
// 		padding: 8,
// 		borderRadius: 4,
// 		margin: 5,
// 	},
// 	addButtonText: {
// 		color: "white",
// 	},
// });

// /**
//  * Styles specifically for the tree items
//  */
// const stylesDragDrop = StyleSheet.create({
// 	treeItemContainer: {
// 		width: "100%",
// 		backgroundColor: "#fff",
// 		borderRadius: 4,
// 		marginVertical: 1,
// 		borderWidth: 1,
// 		borderColor: "#e0e0e0",
// 	},
// 	itemInner: {
// 		flexDirection: "row",
// 		alignItems: "center",
// 		padding: 10,
// 	},
// 	expandButton: {
// 		width: 24,
// 		height: 24,
// 		alignItems: "center",
// 		justifyContent: "center",
// 		marginRight: 8,
// 	},
// 	expandIcon: {
// 		fontSize: 17,
// 		color: "#444",
// 	},
// 	itemText: {
// 		fontSize: 14,
// 		color: "#333",
// 		flex: 1,
// 	},
// 	selectedText: {
// 		color: "#1976d2",
// 		fontWeight: "500",
// 	},
// 	selectedItem: {
// 		backgroundColor: "#e3f2fd",
// 	},
// 	arrowButton: {
// 		marginLeft: 4,
// 		backgroundColor: "#f0f0f0",
// 		paddingHorizontal: 6,
// 		paddingVertical: 2,
// 		borderRadius: 4,
// 	},
// 	arrowText: {
// 		fontSize: 14,
// 		color: "#555",
// 	},
// });

import React, { useEffect, useState, useRef } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	Pressable,
	TouchableOpacity,
} from "react-native"; // For web, Expo compiles to react-native-web
import styled from "styled-components/native";
import { Divider } from "react-native-elements";
import { useStoreActions, useStoreState } from "easy-peasy";

// ----- Imported from your original code -----
import Form from "../containers/Form";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import Input from "../presentational/Input";
import Switch from "../presentational/Switch";
import Button from "../presentational/Button";
import Gap from "../presentational/Gap";
import Modal from "../functional/Modal";
import ToolSelectionModal from "../containers/Modal/TestSelectionModal";
import WorkScopeToolList from "../functional/List/WorkScopeToolList";
import Page from "../containers/Page/Page";
import { Breadcrumb } from "../presentational/Breadcrumb";
import { height } from "../../utilities/general";
// --------------------------------------------

/**
 * A styled label from your code
 */
const Label = styled(Text)`
	color: #00205b;
`;

/**
 * TreeItem component
 * - Displays arrow icons for Up/Down/Indent/Outdent
 * - Each arrow has an accessibilityLabel for screen readers
 */
const TreeItem = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
	isEditMode,
	moveItemUp,
	moveItemDown,
	indentItem,
	outdentItem,
}) => {
	const hasChildren = !!(item.children && item.children.length > 0);
	const isExpanded = expandedIds.has(item.id);
	const isSelected = selectedId === item.id;

	return (
		<View style={{ marginLeft: level * 20 }}>
			<Pressable
				onPress={() => setSelectedId(item.id)}
				style={[
					stylesDragDrop.treeItemContainer,
					isSelected && stylesDragDrop.selectedItem,
				]}
			>
				<View style={stylesDragDrop.itemInner}>
					{/* Expand/collapse toggle if children exist */}
					{hasChildren && (
						<TouchableOpacity
							style={stylesDragDrop.expandButton}
							onPress={() => toggleExpanded(item.id)}
							accessibilityLabel={
								isExpanded ? "Collapse children" : "Expand children"
							}
							accessibilityRole="button"
						>
							<Text style={stylesDragDrop.expandIcon}>
								{isExpanded ? "-" : "+"}
							</Text>
						</TouchableOpacity>
					)}

					{/* Item name */}
					<Text
						style={[
							stylesDragDrop.itemText,
							isSelected && stylesDragDrop.selectedText,
						]}
					>
						{item.name}
					</Text>

					{/* Only show arrow controls if in edit mode */}
					{isEditMode && (
						<View style={stylesDragDrop.buttonGroup}>
							{/* Move Up */}
							<TouchableOpacity
								style={stylesDragDrop.arrowButton}
								onPress={() => moveItemUp(item.id)}
								accessibilityLabel="Move item up"
								accessibilityRole="button"
							>
								<Text style={stylesDragDrop.arrowText}>↑</Text>
							</TouchableOpacity>

							{/* Move Down */}
							<TouchableOpacity
								style={stylesDragDrop.arrowButton}
								onPress={() => moveItemDown(item.id)}
								accessibilityLabel="Move item down"
								accessibilityRole="button"
							>
								<Text style={stylesDragDrop.arrowText}>↓</Text>
							</TouchableOpacity>

							{/* Indent (make child of preceding sibling) */}
							<TouchableOpacity
								style={stylesDragDrop.arrowButton}
								onPress={() => indentItem(item.id)}
								accessibilityLabel="Indent item (make child of previous sibling)"
								accessibilityRole="button"
							>
								<Text style={stylesDragDrop.arrowText}>→</Text>
							</TouchableOpacity>

							{/* Outdent (move up one level) */}
							<TouchableOpacity
								style={stylesDragDrop.arrowButton}
								onPress={() => outdentItem(item.id)}
								accessibilityLabel="Outdent item (move up one level)"
								accessibilityRole="button"
							>
								<Text style={stylesDragDrop.arrowText}>←</Text>
							</TouchableOpacity>
						</View>
					)}
				</View>
			</Pressable>

			{/* Render children if expanded */}
			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<TreeItem
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
							isEditMode={isEditMode}
							moveItemUp={moveItemUp}
							moveItemDown={moveItemDown}
							indentItem={indentItem}
							outdentItem={outdentItem}
						/>
					))}
				</View>
			)}
		</View>
	);
};

/**
 * ContentView: (Mostly unchanged).
 * Shows the right-hand form for the selected item.
 */
const ContentView = ({ selectedItem }) => {
	const [selectedWS, setSelectedWS] = useState({
		workscopename: "",
		enginefamilyid: "",
		enginevariantid: "",
		workpackageid: "",
		technicalmanualreference: "",
		quantity: "",
		wiptool: "",
		usewith: "",
	});

	const {
		workscope: { input, toolInput, list, toolList },
		workpackage: { list: wpList },
	} = useStoreState((state) => state);

	const {
		getWorkScopeToolsThunk,
		setWorkScopeInputAction,
		setWorkScopeToolInputAction,
		upsertWorkScopeToolThunk,
		upsertWorkScopeThunk,
		deleteWorkScopeThunk,
	} = useStoreActions((actions) => actions);

	const [toolModalVisible, setToolSelectionModalVisible] = useState(false);

	useEffect(() => {
		if (!selectedItem) return;
		if (input.id !== selectedItem.id) {
			const selectedData = list.find((ws) => ws.id === selectedItem.id);
			if (selectedData) {
				setWorkScopeInputAction(selectedData);
				getWorkScopeToolsThunk({ id: selectedItem.id });
			}
		}
	}, [
		selectedItem,
		list,
		input.id,
		setWorkScopeInputAction,
		getWorkScopeToolsThunk,
	]);

	// Breadcrumb
	const [breadcrumbItems, setBreadcrumbItems] = useState([]);
	useEffect(() => {
		const items = [
			{
				id: "engineFamily",
				name: "LEAP",
			},
			{
				id: "engineVariant",
				name: "1A",
			},
		];

		if (input.wsparentid) {
			const parentWorkscope = list.find((ws) => ws.id === input.wsparentid);
			if (parentWorkscope) {
				items.push({
					id: parentWorkscope.id,
					name: parentWorkscope.workscopename,
				});
			}
			if (input.workscopename) {
				items.push({
					id: input.id,
					name: input.workscopename,
				});
			}
		}
		setBreadcrumbItems(items);
	}, [
		input.enginefamilyid,
		input.enginevariantid,
		input.wsparentid,
		list,
		toolList,
	]);

	return (
		<View style={styles.contentContainer}>
			<Modal
				title="Add Work Scope Tool"
				isVisible={toolModalVisible}
				onClose={() => setToolSelectionModalVisible(false)}
			>
				<Row>
					<Col>
						<Input
							label="Part Number"
							onChangeText={(partnumber) =>
								setWorkScopeToolInputAction({ partnumber })
							}
							value={toolInput.partnumber}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Input
							label="Tool Nomenclature"
							onChangeText={(toolnomenclature) =>
								setWorkScopeToolInputAction({ toolnomenclature })
							}
							value={toolInput.toolnomenclature}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Input
							label="Qualified Revision"
							onChangeText={(qualifiedrevision) =>
								setWorkScopeToolInputAction({ qualifiedrevision })
							}
							value={toolInput.qualifiedrevision}
							required
						/>
					</Col>
					<Col xs={12} md={4}>
						<Input
							label="Dimensions"
							onChangeText={(dimensions) =>
								setWorkScopeToolInputAction({ dimensions })
							}
							value={toolInput.dimensions}
							required
						/>
					</Col>
					<Col xs={12} md={4}>
						<Input
							label="Weight"
							onChangeText={(weight) => setWorkScopeToolInputAction({ weight })}
							value={toolInput.weight}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={4}>
						<Input
							label="Load Test"
							onChangeText={(loadtest) =>
								setWorkScopeToolInputAction({ loadtest })
							}
							value={toolInput.loadtest}
							required
						/>
					</Col>
					<Col xs={12} md={4}>
						<Input
							label="Tool Code"
							onChangeText={(toolcode) =>
								setWorkScopeToolInputAction({ toolcode })
							}
							value={toolInput.toolcode}
							required
						/>
					</Col>
					<Col xs={12} md={4}>
						<Input
							label="Calibration"
							onChangeText={(calibration) =>
								setWorkScopeToolInputAction({ calibration })
							}
							value={toolInput.calibration}
							required
						/>
					</Col>
				</Row>

				<View style={styles.modalFooter}>
					<TouchableOpacity style={[styles.button, styles.cancelButton]}>
						<Text style={styles.buttonText}>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.button, styles.saveButton]}
						onPress={() => {
							upsertWorkScopeToolThunk();
						}}
					>
						<Text style={[styles.buttonText, styles.saveButtonText]}>
							Save Selection
						</Text>
					</TouchableOpacity>
				</View>
			</Modal>

			<View style={styles.breadcrumbContainer}>
				<Divider />
			</View>

			{selectedItem ? (
				<Form>
					<Row>
						<Col xs={12} md={8}>
							<Input
								label="Work Scope Name"
								onChangeText={(workscopename) =>
									setWorkScopeInputAction({ workscopename })
								}
								value={input.workscopename}
								required
							/>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									deleteWorkScopeThunk();
								}}
								color="#ab2328"
								fullWidth
							>
								Delete
							</Button>
						</Col>
						<Col xs={12} md={2}>
							<Button
								style={{ borderRadius: 8 }}
								onPress={() => {
									upsertWorkScopeThunk();
								}}
								color="#1976d2"
								fullWidth
							>
								Save
							</Button>
						</Col>
					</Row>

					<Row>
						<Col>
							<Input
								label="Work Scope Description"
								onChangeText={(workscopedescription) =>
									setWorkScopeInputAction({ workscopedescription })
								}
								value={input.workscopedescription}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={5}>
							<Input
								label="Work Scope Parent"
								onChangeText={(wsparentid) =>
									setWorkScopeInputAction({ wsparentid })
								}
								value={input.wsparentid}
								required
							/>
						</Col>
						<Col xs={12} md={7}>
							<Input
								label="Work Scope Order"
								onChangeText={(ordernum) =>
									setWorkScopeInputAction({ ordernum })
								}
								value={input.ordernum}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={3}>
							<Input
								label="Work Package"
								value={
									input.workpackageid
										? wpList?.find((w) => w.id === input.workpackageid)
												?.description
										: "N/A"
								}
								onSelect={(item) => {
									setWorkScopeInputAction({
										workpackageid: item.id,
										wpdescription: item.description,
									});
								}}
								required
								dropdown
								dropdownChoices={wpList}
								selectedItem={(item) => item.description}
								rowTextForSelection={(item) => item.description}
							/>
						</Col>
						<Col xs={12} md={9}>
							<Input
								editable={false}
								label="Work Package Description"
								onChangeText={(wpdescription) =>
									setWorkScopeInputAction({ wpdescription })
								}
								value={input.wpdescription || "N/A"}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<Label>Notes</Label>
							<Input
								multiline
								numberOfLines={4}
								onChangeText={(notes) => setWorkScopeInputAction({ notes })}
								value={input.notes}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<View style={styles.toolsHeader}>
								<Label>Tools</Label>
								<TouchableOpacity
									style={styles.addButton}
									onPress={() => setToolSelectionModalVisible(true)}
								>
									<Text style={styles.addButtonText}>Add</Text>
								</TouchableOpacity>
							</View>
							<WorkScopeToolList />
						</Col>
					</Row>
				</Form>
			) : (
				<Text style={styles.noContentText}>Select an item to view details</Text>
			)}
		</View>
	);
};

/**
 * The main TreeView containing the hierarchical list on the left
 * and the ContentView details form on the right.
 */
const TreeView = () => {
	const scrollViewRef = useRef(null);
	const [data, setData] = useState([]);

	const [selectedId, setSelectedId] = useState(null);
	const [expandedIds, setExpandedIds] = useState(new Set());
	const [isEditMode, setIsEditMode] = useState(false);

	// easy-peasy store references
	const {
		workscope: { selectedData },
	} = useStoreState((state) => state);
	const { getWorkScopeThunk, getWorkPackageThunk } = useStoreActions(
		(actions) => actions
	);

	useEffect(() => {
		getWorkScopeThunk({
			enginefamilyid: 1,
			enginevariantid: 1,
		});
		getWorkPackageThunk({});
	}, [getWorkScopeThunk, getWorkPackageThunk]);

	// Whenever `selectedData` changes (from store), sync to local `data`
	useEffect(() => {
		if (Array.isArray(selectedData)) {
			setData(selectedData);
		}
	}, [selectedData]);

	// Expand/collapse logic
	const toggleExpanded = (id) => {
		setExpandedIds((prev) => {
			const next = new Set(prev);
			if (next.has(id)) {
				next.delete(id);
			} else {
				next.add(id);
			}
			return next;
		});
	};

	// Find selected item in local data (recursive)
	const findSelectedItem = (items, id) => {
		for (const node of items) {
			if (node.id === id) return node;
			if (node.children) {
				const found = findSelectedItem(node.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	/**
	 * Helper functions for reordering
	 */
	const clone = (obj) => JSON.parse(JSON.stringify(obj));

	// Find the parent of an item with given id
	const findParent = (items, targetId, parent = null) => {
		for (const node of items) {
			if (node.id === targetId) {
				return parent;
			}
			if (node.children) {
				const found = findParent(node.children, targetId, node);
				if (found) return found;
			}
		}
		return null;
	};

	// Move item up among siblings
	const moveItemUp = (itemId) => {
		setData((prevData) => {
			const newData = clone(prevData);
			const parent = findParent(newData, itemId);
			const siblings = parent ? parent.children : newData;
			if (!siblings) return prevData;

			const idx = siblings.findIndex((s) => s.id === itemId);
			if (idx > 0) {
				[siblings[idx - 1], siblings[idx]] = [siblings[idx], siblings[idx - 1]];
			}
			return newData;
		});
	};

	// Move item down among siblings
	const moveItemDown = (itemId) => {
		setData((prevData) => {
			const newData = clone(prevData);
			const parent = findParent(newData, itemId);
			const siblings = parent ? parent.children : newData;
			if (!siblings) return prevData;

			const idx = siblings.findIndex((s) => s.id === itemId);
			if (idx < siblings.length - 1) {
				[siblings[idx], siblings[idx + 1]] = [siblings[idx + 1], siblings[idx]];
			}
			return newData;
		});
	};

	// Indent (→) => make child of the previous sibling
	const indentItem = (itemId) => {
		setData((prevData) => {
			const newData = clone(prevData);

			const parent = findParent(newData, itemId);
			const siblings = parent ? parent.children : newData;
			const idx = siblings.findIndex((s) => s.id === itemId);

			if (idx > 0) {
				const itemToIndent = siblings[idx];
				const newParent = siblings[idx - 1]; // preceding sibling
				siblings.splice(idx, 1);

				newParent.children = newParent.children || [];
				newParent.children.push(itemToIndent);

				// auto-expand the new parent
				if (!expandedIds.has(newParent.id)) {
					setExpandedIds((prev) => {
						const next = new Set(prev);
						next.add(newParent.id);
						return next;
					});
				}
			}
			return newData;
		});
	};

	// Outdent (←) => move to parent's siblings
	const outdentItem = (itemId) => {
		setData((prevData) => {
			const newData = clone(prevData);
			const parent = findParent(newData, itemId);
			if (!parent) return newData; // already top-level

			const grandParent = findParent(newData, parent.id);
			const idx = parent.children.findIndex((c) => c.id === itemId);
			if (idx === -1) return newData;

			const itemToOutdent = parent.children[idx];
			parent.children.splice(idx, 1);

			if (grandParent) {
				// place item right after the parent in grandParent.children
				const gpSiblings = grandParent.children;
				const parentIndex = gpSiblings.findIndex((s) => s.id === parent.id);
				gpSiblings.splice(parentIndex + 1, 0, itemToOutdent);
			} else {
				// no grandparent => move to top-level
				newData.push(itemToOutdent);
			}
			return newData;
		});
	};

	return (
		<Page style={styles.container}>
			{/* Top bar with the Edit toggle */}
			<View style={styles.topBar}>
				<Text style={styles.title}>Tree View</Text>
				<TouchableOpacity
					style={[styles.editButton, isEditMode && styles.editButtonActive]}
					onPress={() => setIsEditMode(!isEditMode)}
				>
					<Text style={styles.editButtonText}>
						{isEditMode ? "Done" : "Edit"}
					</Text>
				</TouchableOpacity>
			</View>

			<View style={styles.mainContent}>
				{/* LEFT: Tree */}
				<View style={styles.treeOuterContainer}>
					<ScrollView
						style={{ flex: 1 }}
						ref={scrollViewRef}
						contentContainerStyle={{ flexGrow: 1 }}
					>
						{data.map((item) => (
							<TreeItem
								key={item.id}
								item={item}
								level={0}
								selectedId={selectedId}
								setSelectedId={setSelectedId}
								expandedIds={expandedIds}
								toggleExpanded={toggleExpanded}
								isEditMode={isEditMode}
								moveItemUp={moveItemUp}
								moveItemDown={moveItemDown}
								indentItem={indentItem}
								outdentItem={outdentItem}
							/>
						))}
					</ScrollView>
				</View>

				{/* RIGHT: ContentView (details form) */}
				<View style={styles.contentView}>
					<ContentView selectedItem={findSelectedItem(data, selectedId)} />
				</View>
			</View>
		</Page>
	);
};

export default TreeView;

/**
 * Styles
 */
const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	topBar: {
		height: 60,
		paddingHorizontal: 20,
		backgroundColor: "#f5f5f5",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		borderBottomWidth: 1,
		borderBottomColor: "#ccc",
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	editButton: {
		paddingHorizontal: 12,
		paddingVertical: 6,
		backgroundColor: "#1976d2",
		borderRadius: 4,
	},
	editButtonActive: {
		backgroundColor: "#0d47a1",
	},
	editButtonText: {
		color: "#fff",
		fontSize: 14,
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
		backgroundColor: "#fff",
		minHeight: "100%",
	},
	treeOuterContainer: {
		width: 400,
		backgroundColor: "#fff",
		borderRightWidth: 1,
		borderRightColor: "#e0e0e0",
	},
	contentView: {
		flex: 1,
		backgroundColor: "#fff",
	},
	contentContainer: {
		padding: 20,
	},
	noContentText: {
		fontSize: 16,
		color: "#666",
		textAlign: "center",
	},
	breadcrumbContainer: {
		marginBottom: 20,
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		paddingTop: 10,
		borderTopWidth: 1,
		borderTopColor: "#eee",
	},
	button: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		marginLeft: 12,
	},
	cancelButton: {
		backgroundColor: "#f5f5f5",
	},
	saveButton: {
		backgroundColor: "#1976d2",
	},
	buttonText: {
		fontSize: 14,
		color: "#333",
	},
	saveButtonText: {
		color: "white",
	},
	toolsHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	addButton: {
		backgroundColor: "#1976d2",
		padding: 8,
		borderRadius: 4,
		margin: 5,
	},
	addButtonText: {
		color: "white",
	},
});

/**
 * Styles specifically for the tree items
 */
const stylesDragDrop = StyleSheet.create({
	treeItemContainer: {
		width: "100%",
		backgroundColor: "#fff",
		borderRadius: 4,
		marginVertical: 1,
		borderWidth: 1,
		borderColor: "#e0e0e0",
	},
	itemInner: {
		flexDirection: "row",
		alignItems: "center",
		padding: 10,
	},
	expandButton: {
		width: 24,
		height: 24,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
	},
	expandIcon: {
		fontSize: 17,
		color: "#444",
	},
	itemText: {
		fontSize: 14,
		color: "#333",
		flex: 1,
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	buttonGroup: {
		flexDirection: "row",
		marginLeft: 8,
	},
	arrowButton: {
		marginLeft: 4,
		backgroundColor: "#f0f0f0",
		paddingHorizontal: 8,
		paddingVertical: 5,
		borderRadius: 4,
	},
	arrowText: {
		fontSize: 14,
		color: "#555",
	},
});
