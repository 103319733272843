import React from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Animated,
	StyleSheet,
} from "react-native";

const TreeItem = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
	readOnly = false,
}) => {
	const hasChildren = item.children && item.children.length > 0;
	const isExpanded = expandedIds.has(item.id);
	const isSelected = selectedId === item.id;

	const fadeAnim = new Animated.Value(0);

	React.useEffect(() => {
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 300,
			useNativeDriver: true,
		}).start();
	}, []);

	return (
		<Animated.View style={{ opacity: fadeAnim }}>
			<View
				style={[
					styles.treeItem,
					{ marginLeft: level * 20 },
					isSelected && styles.selectedItem,
				]}
			>
				{hasChildren && (
					<TouchableOpacity
						style={styles.expandButton}
						onPress={() => toggleExpanded(item.id)}
					>
						<Text style={styles.expandIcon}>{isExpanded ? "-" : "+"}</Text>
					</TouchableOpacity>
				)}
				<TouchableOpacity
					style={styles.textContainer}
					onPress={() => !readOnly && setSelectedId(item.id)}
					disabled={readOnly}
				>
					<Text
						style={[
							styles.itemText,
							isSelected && styles.selectedText,
							readOnly && styles.readOnlyText,
						]}
					>
						{item.name}
					</Text>
				</TouchableOpacity>
			</View>

			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<TreeItem
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
							readOnly={readOnly}
						/>
					))}
				</View>
			)}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	treeItem: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#f0f0f0",
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	expandButton: {
		padding: 4,
		marginRight: 8,
		width: 20,
		height: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	expandIcon: {
		fontSize: 18,
		fontWeight: "bold",
		color: "#666",
		lineHeight: 20,
	},
	textContainer: {
		flex: 1,
	},
	itemText: {
		fontSize: 14,
		color: "#333",
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	readOnlyText: {
		color: "#666",
	},
});

export default TreeItem;
