import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Button from "../../containers/Button";
import Col from "../../presentational/Col";
import Page from "../../containers/Page";
import SearchBar from "../../containers/SearchBar";
import React, { useEffect } from "react";
import List from "../../containers/List";
import Gap from "../../presentational/Gap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import FileUploadedModal from "../../containers/Modal/FileUploadedModal";
import UploadSelectionModal from "../../containers/Modal/UploadSelectionModal";
import ToolStatusDisplay from "../../presentational/ToolStatusDisplay/ToolStatusDisplay";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function HomePage({ navigation }) {
	const isFocused = useIsFocused();
	const { email } = useStoreState((state) => state.user.authenticatedUser);
	const {
		leap: {
			CRTLImporting,
			totalCRTLToolsToImport,
			totalCRTLToolsProcessed: totalToolsProcessed,
			successfulCRTLImportList: successfulImports,
			failedCRTLImportList: failedImports,
			isPreparingControlFile,
		},
		azure: { isReconnectingToSignalR },
		file: { isUploadingFile },
		tool: { totalToolsToImport, totalToolsImported, totalToolsNotImported },
	} = useStoreState((state) => state);

	const {
		getUsersThunk,
		getOEMListThunk,
		getEngineFamilyListThunk,
		getWorkPackageThunk,
		getEngineVariantListThunk,
	} = useStoreActions((actions) => actions);

	useEffect(() => {
		if (isFocused && email && !CRTLImporting) {
			getOEMListThunk();
			getUsersThunk();
			getEngineFamilyListThunk();
			getWorkPackageThunk();
			getEngineVariantListThunk();
		}
	}, [isFocused, email, CRTLImporting]);

	const progressPercentage =
		(totalToolsProcessed / totalCRTLToolsToImport) * 100;

	const workScopeToolProgressPercentage =
		(totalToolsImported / totalToolsToImport) * 100;

	const safeProgressPercentage = isFinite(progressPercentage)
		? progressPercentage.toFixed(2)
		: 0;

	if (isUploadingFile)
		return (
			<ToolStatusDisplay.Container>
				<ToolStatusDisplay
					progressPercentage={
						isFinite(workScopeToolProgressPercentage)
							? workScopeToolProgressPercentage.toFixed(2)
							: 0
					}
					successNum={totalToolsImported}
					failedNum={totalToolsNotImported}
					totalNum={totalToolsToImport}
					isReconnectingToSignalR={isReconnectingToSignalR}
				>
					{isPreparingControlFile && (
						<>
							<Gap />
							<ToolStatusDisplay.Text>
								Preparing to load control file
							</ToolStatusDisplay.Text>
						</>
					)}
				</ToolStatusDisplay>
			</ToolStatusDisplay.Container>
		);
	else if (CRTLImporting)
		return (
			<ToolStatusDisplay.Container>
				<ToolStatusDisplay
					progressPercentage={safeProgressPercentage}
					successNum={successfulImports}
					failedNum={failedImports}
					totalNum={totalCRTLToolsToImport}
					isReconnectingToSignalR={isReconnectingToSignalR}
				/>
			</ToolStatusDisplay.Container>
		);
	else
		return (
			<Page.Protected>
				<Container>
					<FileUploadedModal navigation={navigation} />
					<UploadSelectionModal navigation={navigation} />

					<SRow>
						<Col xs={12} sm={2}>
							<Button.GoToCreateUser>Add User</Button.GoToCreateUser>
						</Col>
						<Col xs={12} sm={2}>
							<Button.GoToRoles>Roles</Button.GoToRoles>
						</Col>
						<Col xs={12} sm={8}>
							<SearchBar.User />
						</Col>
					</SRow>

					<Gap />

					<List.User />
				</Container>
			</Page.Protected>
		);
}

export default HomePage;
