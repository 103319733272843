import {
	Table,
	Menu,
	Input,
	Row,
	Text,
	Pagination,
	Center,
	Div,
	useScreenDimensions,
	Modal,
	remToDp,
} from "@cfbs/cfbsstrap-native";
import { FontAwesome5 } from "@expo/vector-icons";
import Button from "../../presentational/Button";
import styled from "styled-components/native";
import { Pressable, ScrollView, View } from "react-native";
import React, { useState } from "react";
import { CheckBox } from "@rneui/themed";

const EditButton = styled(Pressable)`
	opacity: ${(props) => (props.disabled ? 0.25 : 1)};
`;

Row.FrozenColumn = styled(Row)`
	flex-wrap: nowrap;
`;

Menu.Container = styled(Div)`
	position: relative;
`;
Menu.FilterContainer = styled(Div)`
	position: absolute;
	top: 4px;
	right: 12px;
`;
Menu.Text = styled(Div)`
	font-family: Barlow_500Medium;
`;

Table.Row = styled(Table.tr)`
	flex-wrap: nowrap;
`;
Table.PressableRow = styled(Pressable)`
	cursor: ${(props) => props.cursor};
`;

const Header = styled(Table.th)`
	flex: auto;
	width: ${(props) => props.width};
	padding: 0px;
	flex-wrap: nowrap;
	height: ${(props) => props.height};
`;
Header.Subheader = styled(Div)`
	flex: 1;
	justify-content: center;
	border-right-width: ${(props) => props.borderRightWidth};
	border-color: #dee2e6;
`;
Header.Subrow = styled(Row)`
	justify-content: space-between;
	width: 100%;
`;
Header.Filter = styled(Row)`
	align-items: center;
`;
Header.NameContainer = styled(Center)`
	height: 100%;
	padding: 16px;
`;
Header.Name = styled(Div)`
	font-family: Barlow_600SemiBold;
	text-align: center;
	font-size: ${(props) => props.headerFontSize || "15px"};
	color: #00205b;
`;
Header.ParentRow = styled(Row)`
	flex-wrap: nowrap;
	height: 100%;
`;
Header.ParentName = styled(Header.Name)`
	border-bottom-width: 2px;
	border-color: #dee2e6;
	padding: 4px;
	font-size: 15px;
`;

const Cell = styled(Table.td)`
	flex: auto;
	width: ${(props) => props.width};
	padding: 0px;
	height: ${(props) => props.height};
`;
Cell.ValueContainer = styled(Center)`
	width: 100%;
	height: 100%;
	padding: 24px;
	border-color: #dee2e6;
	text-align: center;
	overflow: hidden;
`;
Cell.Value = styled(Div)`
	font-family: Barlow_400Regular;
	font-size: ${(props) => props.cellFontSize || "13px"};
	color: #00205b;
`;
Cell.Engine = styled(Cell.ValueContainer)`
	flex: 1;
	border-bottom-width: ${(props) => props.borderBottomWidth};
`;
Cell.Subcells = styled(Row)`
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
`;
Cell.Subcell = styled(Div)`
	flex: 1;
`;
Cell.SubcellContainer = styled(Cell.Engine)`
	border-right-width: ${(props) => props.borderRightWidth};
`;

const SPagination = styled(Pagination)`
	margin-top: 20px;
	align-items: center;
`;
SPagination.PageDisplay = styled(Row)`
	align-items: center;
`;
SPagination.Input = styled(Input)`
	border-width: 0.1px;
	border-color: #000;
	padding: 5px;
	width: ${(props) => props.width};
	text-align: center;
	margin-left: 10px;
	margin-right: 10px;
`;

const Buttons = styled(Row)`
	justify-content: flex-end;
`;
Buttons.Ok = styled(Button)`
	margin-right: 8px;
`;

Modal.FullDataRow = styled(Modal)`
	max-width: ${(props) => props.maxWidth};
`;

function FilterHeader({
	columnName,
	isCheckingEqual,
	isCheckingContains,
	isSortAscending,
	isSortDescending,
	...props
}) {
	return (
		<Header.Filter {...props}>
			<FontAwesome5
				name="filter"
				size={10}
				color={isCheckingEqual || isCheckingContains ? "#000000" : "#D3D3D3"}
			/>
			{isSortAscending && "↑"}
			{isSortDescending && "↓"}
		</Header.Filter>
	);
}

function FilterMenu({
	onShowMenu,
	onHideMenu,
	onPressClearFilter,
	onPressSortAscending,
	onPressSortDescending,
	onPressEquals,
	onPressContains,
	onChangeFilterInput,
	onPressOk,
	header,
	headerIndex,
	subheaderIndex,
}) {
	const menuItems = [
		{ label: "Clear Filter", active: false, action: onPressClearFilter },
		{
			label: "Sort Ascending",
			active: header.isSortAscending,
			action: onPressSortAscending,
		},
		{
			label: "Sort Descending",
			active: header.isSortDescending,
			action: onPressSortDescending,
		},
		{ label: "Equals", active: header.pickedEqualCheck, action: onPressEquals },
		{
			label: "Contains",
			active: header.pickedContainsCheck,
			action: onPressContains,
		},
	];

	function getMenuAction(action) {
		return action(headerIndex, subheaderIndex);
	}

	return (
		<Menu.Container>
			<Menu.FilterContainer>
				<Menu.Toggle
					showMenu={header.showMenu}
					onShowMenu={getMenuAction(onShowMenu)}
					onHideMenu={getMenuAction(onHideMenu)}
					Toggle={<FilterHeader {...header} />}
					offsetMenuPosHorizontal={header.offsetMenuPosHorizontal || 0}
				>
					{menuItems.map(({ label, action, active }, index) => (
						<Menu.Item key={index}>
							<Pressable onPress={getMenuAction(action)}>
								<Menu.Text>
									{active && "-> "}
									{label}
								</Menu.Text>
							</Pressable>
						</Menu.Item>
					))}

					<Menu.Item>
						<Input.Search
							placeholder="Type filter value here"
							value={header.filterValue}
							onChangeText={getMenuAction(onChangeFilterInput)}
						/>
					</Menu.Item>

					<Menu.Item>
						<Buttons>
							<Buttons.Ok
								color="#0088CE"
								onPress={onPressOk(header, headerIndex, subheaderIndex)}
							>
								OK
							</Buttons.Ok>

							<Button onPress={getMenuAction(onHideMenu)}>CANCEL</Button>
						</Buttons>
					</Menu.Item>
				</Menu.Toggle>
			</Menu.FilterContainer>
		</Menu.Container>
	);
}

function useFullDataViewModal(list) {
	const [rowIndex, setRowIndex] = useState(-1);
	const { vwToDp } = useScreenDimensions();
	const data = list[rowIndex];

	return {
		rowIndex,
		dataToView: data ? [data] : [],
		onPressDataRow: (index) => () => setRowIndex(index),
		onRequestClose: () => setRowIndex(-1),
		visible: rowIndex > -1,
		maxWidth: `${vwToDp(90)}px`,
	};
}

function GroupedList({
	headers,
	list,
	pageNumber,
	totalPageNum,
	onPressFirstPage,
	onPressPreviousPage,
	onPressNextPage,
	onPressLastPage,
	onChangePageNum,
	onEnterPageNum,
	onPressEdit,
	filterEvents,
	isScrollableHorizontally,
	onPressCheckbox,
	isRowExpandable = false, // New prop to enable row expandability
	renderExpandedContent = () => null, // New prop for custom expanded content
	headerFontSize = 15,
	cellFontSize = 13,
}) {
	const { vwToDp, vhToDp } = useScreenDimensions();
	const isOnFirstPage = pageNumber === 1;
	const isOnLastPage = pageNumber === totalPageNum;
	const frozenColumnStartIndex = headers.findIndex((header) => header.freeze);
	const afterFrozenColumnIndex = frozenColumnStartIndex + 1;
	const isFreezingAColumn = frozenColumnStartIndex > -1;
	const numberOfLines = isFreezingAColumn ? 5 : undefined;
	const { rowIndex, dataToView, onPressDataRow, ...modalProps } =
		useFullDataViewModal(list);

	const [expandedRows, setExpandedRows] = useState([]);

	const toggleRowExpansion = (index) => {
		setExpandedRows((prevExpandedRows) =>
			prevExpandedRows.includes(index)
				? prevExpandedRows.filter((i) => i !== index)
				: [...prevExpandedRows, index]
		);
	};

	function renderSubheaders(subheaders, headerIndex) {
		return (
			<Row>
				{subheaders.map((subheader, subheaderIndex) => (
					<Header.Subheader
						key={subheaderIndex}
						headerFontSize={headerFontSize}
						borderRightWidth={`${
							subheaderIndex < subheaders.length - 1 ? 2 : 0
						}px`}
					>
						<Header.Subrow>
							<View />

							<Header.NameContainer>
								<Header.Name headerFontSize={headerFontSize}>
									{subheader.columnName}
								</Header.Name>
							</Header.NameContainer>

							<FilterMenu
								header={subheader}
								headerIndex={headerIndex}
								subheaderIndex={subheaderIndex}
								{...filterEvents}
							/>
						</Header.Subrow>
					</Header.Subheader>
				))}
			</Row>
		);
	}

	function renderTableHeaders(headers) {
		return (
			<Table.Row>
				{headers.map((header, headerIndex) => (
					<Header
						key={headerIndex}
						width={header.width || `${vwToDp(header.subheaders ? 30 : 10)}px`}
						height={isFreezingAColumn ? `${vhToDp(8)}px` : "100%"}
					>
						{(header.edit || header.checkbox) && (
							<Header.NameContainer>
								<Header.Name headerFontSize={headerFontSize}>
									{header.columnName}
								</Header.Name>
							</Header.NameContainer>
						)}

						{!header.edit && !header.checkbox && header.subheaders && (
							<Div>
								<Header.ParentName>{header.columnName}</Header.ParentName>
								{renderSubheaders(header.subheaders, headerIndex)}
							</Div>
						)}

						{!header.edit && !header.checkbox && !header.subheaders && (
							<Header.ParentRow>
								<View />

								<Header.NameContainer>
									<Header.Name headerFontSize={headerFontSize}>
										{header.columnName}
									</Header.Name>
								</Header.NameContainer>

								<FilterMenu
									header={header}
									headerIndex={headerIndex}
									{...filterEvents}
								/>
							</Header.ParentRow>
						)}
					</Header>
				))}
			</Table.Row>
		);
	}

	function renderTableData(headers, desiredList, isFreezingAColumn) {
		return (desiredList || list).map((item, rowIndex) => (
			<React.Fragment key={rowIndex}>
				<Table.PressableRow
					onPress={
						isFreezingAColumn
							? onPressDataRow(rowIndex)
							: isRowExpandable
							? () => {
									toggleRowExpansion(rowIndex);
							  }
							: () => {}
					}
					cursor={isFreezingAColumn ? "pointer" : "default"}
				>
					<Table.Row key={rowIndex} striped={rowIndex % 2 === 0}>
						{headers.map((header, headerIndex) => (
							<Cell
								key={headerIndex}
								width={
									header.width || `${vwToDp(header.subheaders ? 30 : 10)}px`
								}
								height={isFreezingAColumn ? `${vhToDp(10)}px` : "100%"}
							>
								{header.subheaders && !header.edit && (
									<Cell.Subcells>
										{header.subheaders.map((subheader, subheaderIndex) => (
											<Cell.Subcell key={subheaderIndex}>
												{subheader.showMultipleEngines ? (
													item.engines.map((engine, engineIndex) => (
														<Cell.SubcellContainer
															key={engineIndex}
															borderRightWidth={`${
																subheaderIndex < header.subheaders.length - 1
																	? 2
																	: 0
															}px`}
															borderBottomWidth={`${
																engineIndex < item.engines.length - 1 ? 2 : 0
															}px`}
														>
															<Cell.Value
																numberOfLines={numberOfLines}
																cellFontSize={cellFontSize}
															>
																{engine[subheader.propertyName]}
															</Cell.Value>
														</Cell.SubcellContainer>
													))
												) : (
													<Cell.SubcellContainer
														borderRightWidth={`${
															subheaderIndex < header.subheaders.length - 1
																? 2
																: 0
														}px`}
													>
														<Cell.Value
															numberOfLines={numberOfLines}
															cellFontSize={cellFontSize}
														>
															{item[subheader.propertyName]}
														</Cell.Value>
													</Cell.SubcellContainer>
												)}
											</Cell.Subcell>
										))}
									</Cell.Subcells>
								)}

								{!header.subheaders && !header.edit && !header.checkbox && (
									<>
										{header.showMultipleEngines ? (
											item.engines.map((engine, engineIndex) => (
												<Cell.Engine
													key={engineIndex}
													borderBottomWidth={`${
														engineIndex < item.engines.length - 1 ? 2 : 0
													}px`}
												>
													<Cell.Value numberOfLines={numberOfLines}>
														{engine[header.propertyName]}
													</Cell.Value>
												</Cell.Engine>
											))
										) : (
											<Cell.ValueContainer>
												<Cell.Value
													numberOfLines={numberOfLines}
													cellFontSize={cellFontSize}
												>
													{item[header.propertyName]}
												</Cell.Value>
											</Cell.ValueContainer>
										)}
									</>
								)}

								{header.edit && (
									<Cell.ValueContainer>
										<EditButton
											onPress={onPressEdit && onPressEdit(item)}
											disabled={!onPressEdit}
										>
											<FontAwesome5 name="edit" size={20} />
										</EditButton>
									</Cell.ValueContainer>
								)}

								{header.checkbox && (
									<Cell.ValueContainer>
										<CheckBox
											checked={item.checked}
											checkedColor="#007AFF"
											onPress={onPressCheckbox(item)}
											checkedIcon={
												<FontAwesome5
													name="check-square"
													size={remToDp(1.5)}
													color="#007AFF"
												/>
											}
											iconType="material-community"
											uncheckedIcon={"checkbox-blank-outline"}
											containerStyle={{ backgroundColor: "none" }}
										/>
									</Cell.ValueContainer>
								)}
							</Cell>
						))}
					</Table.Row>
				</Table.PressableRow>

				{isRowExpandable && expandedRows.includes(rowIndex) && (
					<Table.Row striped={false}>
						<Table.td colSpan={headers.length}>
							<View style={{ padding: 10 }}>
								{renderExpandedContent(item, rowIndex)}
							</View>
						</Table.td>
					</Table.Row>
				)}
			</React.Fragment>
		));
	}

	return (
		<>
			<Table border hover={isFreezingAColumn ? undefined : true} striped>
				{isFreezingAColumn ? (
					<Row.FrozenColumn>
						<Div>
							{renderTableHeaders(headers.slice(0, afterFrozenColumnIndex))}
							{renderTableData(
								headers.slice(0, afterFrozenColumnIndex),
								list,
								true
							)}
						</Div>
						<ScrollView horizontal={isScrollableHorizontally}>
							<Div>
								{renderTableHeaders(headers.slice(afterFrozenColumnIndex))}
								{renderTableData(
									headers.slice(afterFrozenColumnIndex),
									list,
									true
								)}
							</Div>
						</ScrollView>
					</Row.FrozenColumn>
				) : (
					<ScrollView horizontal={isScrollableHorizontally}>
						<Div>
							{renderTableHeaders(headers)}
							{renderTableData(headers)}
						</Div>
					</ScrollView>
				)}
			</Table>

			<Center>
				<SPagination>
					<Pagination.Item disabled={isOnFirstPage} onPress={onPressFirstPage}>
						<Pagination.First />
					</Pagination.Item>

					<Pagination.Item
						disabled={isOnFirstPage}
						onPress={onPressPreviousPage}
					>
						<Pagination.Previous />
					</Pagination.Item>

					<Pagination.Item>
						<SPagination.PageDisplay>
							<Text>Page</Text>
							<SPagination.Input
								width={35 + `${pageNumber}`.length * 10}
								keyboardType="numeric"
								placeholder={pageNumber}
								value={pageNumber}
								maxLength={Math.floor(Math.log10(totalPageNum) + 1)}
								onChangeText={onChangePageNum}
								onSubmitEditing={onEnterPageNum}
							/>
							<Text>of {totalPageNum}</Text>
						</SPagination.PageDisplay>
					</Pagination.Item>

					<Pagination.Item disabled={isOnLastPage} onPress={onPressNextPage}>
						<Pagination.Next />
					</Pagination.Item>

					<Pagination.Item disabled={isOnLastPage} onPress={onPressLastPage}>
						<Pagination.Last />
					</Pagination.Item>
				</SPagination>
			</Center>

			{isFreezingAColumn && (
				<Modal.FullDataRow {...modalProps}>
					<Modal.Header closeButton>{""}</Modal.Header>
					<Modal.Body>
						<Table border hover striped={rowIndex % 2 === 0}>
							{renderTableHeaders(headers)}
							{renderTableData(headers, dataToView, false)}
						</Table>
					</Modal.Body>
				</Modal.FullDataRow>
			)}
		</>
	);
}

export default GroupedList;
